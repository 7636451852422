import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../partials/BreadCrumb';
import CardHeader from '../../partials/miniComponent/CardHeader/CardHeader';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Constants from '../../../Constants';
import Swal from 'sweetalert2';
import NoDataFound from '../../partials/miniComponent/NoDataFound';
import Loader from '../../partials/miniComponent/Loader';
import Pagination from 'react-js-pagination';
import Form from 'react-bootstrap/Form';
import { getUserPermissions } from '../../../services/authService';

const User = () => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [input, setInput] = useState({
        order_by: 'created_at',
        per_page: 10,
        direction: 'desc',
        search: '',
    });
    const [formErrors, setFormErrors] = useState('');
    const [userId, setUserId] = useState();
    const [selectedFile, setSelectedFile] = useState(null); // Nouvel état pour stocker le fichier sélectionné
    const [roles, setRoles] = useState([]);
    const [input2, setInput2] = useState({});
    const handleLogo = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();    
        reader.onloadend = () => {
            setSelectedFile(file);
            setInput2((prevState) => ({ ...prevState, photoPreview: reader.result }));
            setInput2((prevState) => ({ ...prevState, photo: reader.result }));
            //setInput((prevState) => ({ ...prevState, photoPreview: reader.result }));
            //setInput((prevState) => ({ ...prevState, logo: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const fetchUserPermissions = async () => {
        try {
          const permissions = await getUserPermissions();
          setUserPermissions(permissions);
          //console.log(userPermissions)
        } catch (error) {
          console.error('Error fetching user permissions:', error);
        }
      };
  

    useEffect(() => {
      fetchUserPermissions();
    }, []);

    const handleInput2 = (e) => {
        setInput2((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
      };

    const handleCloseModal = () => {
        // Réinitialiser les erreurs
        setFormErrors('');
        // Réinitialiser les données du formulaire
        setFormData({
            name: '',
            email: '',
            phone: '',
            photo: null,
            password: '',
            role_id: '',
        });
        // Fermer la modal
        setModalShow(false);
    };
    const handleErrors = (error) => {
        if (error.response && error.response.status === 422) {
            setErrors(error.response.data.errors);
        } else {
            // Gérer d'autres erreurs ici si nécessaire
        }
    };
    const handleInput = (e) =>{
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        //setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        photo: null,
        password: '',
        role_id: '',
    });

    const buildImagePath = (relativePath) => {
        // Remplacez 'http://localhost' par l'URL de base de votre site
        return `${relativePath}`;
      };

      const fetchDataForUser = async (id) => {
        try {
            const response = await axios.get(`${Constants.BASE_URL}/users/${id}`);
            return response.data.data;
        } catch (error) {
            // Gérez les erreurs ici
            console.error('Erreur lors de la récupération des données utilisateur:', error);
            throw error; // Rejetez l'erreur pour indiquer qu'il y a eu un problème
        }
    };

    const updateUserListAfterDelete = (attributeId) => {
        // Filtrer les catégories pour exclure celle supprimée
        //const updatedAttributes = categories.filter(attribute => attribute.id !== attributeId);
        //setAttributes(updatedAttributes);

      };

    const handleUserDelete = (id) =>{
        //console.log($id);
        Swal.fire({
            title: 'Etes-vous sûr?',
            text: "Cet Utilisateur sera supprimé !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constants.BASE_URL}/users/${id}`).then(res=>{
                    //updateUserListAfterDelete(id); // Mettre à jour la liste après la suppression
                    getUsers(1);
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.msg,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500
                    })
           
                });
            }
          })


    }
    
    const handleModal = async (category = undefined) => {
        // Réinitialisez les erreurs
        setErrors([]);
    
        if (category !== undefined) {
            try {
                // Utilisez les données de la catégorie directement au lieu de la fonction fetchDataForUser
                
                const userDataPromise = fetchDataForUser(category.id);

                console.log(fetchDataForUser(category.id));

                userDataPromise.then(userData => {
                    setInput2(userData);
                    setUserId(userData.id); // Ajoutez cette ligne pour définir l'ID de l'utilisateur

                    console.log(userData); });
                    
                
                const userData = {
                    name: category.name,
                    email: category.email,
                    phone: category.phone,
                    photo: category.photo,
                    password: category.password,
                    role_id: category.role.id,
                };
    
                //console.log(userData);
    
                // Définissez les valeurs du formulaire avec les données de l'utilisateur existant
                setInput2({
                    name: userData.name,
                    email: userData.email,
                    phone: userData.phone,
                    photo: userData.photo,
                    password: userData.password,
                    role_id: userData.role_id,
                });
    
                setModalTitle('Modifier');
                setIsEditMode(true);
                // Ouvrez la modal ici, après avoir récupéré les données de l'utilisateur
                setModalShow(true);
            } catch (error) {
                // Gérez l'erreur, peut-être affichez un message d'erreur à l'utilisateur
            }
        } else {
            // ... le reste du code pour la création d'utilisateur
            // Si category n'est pas défini, c'est une création d'utilisateur, réinitialisez l'ID
            setUserId(null);
    
            setModalTitle('Ajouter');
            setIsEditMode(false);
    
            // Réinitialisez les valeurs du formulaire pour une nouvelle création d'utilisateur
            setInput2({
                name: '',
                email: '',
                phone: '',
                photo: '',
                password: '',
                role_id: '',
            });
    
            // Ouvrez la modal
            setModalShow(true);
        }
    };
    
    
    
    
    
    
    
    
    
    
    


    


    const handleUserUpdate = () => {
        setIsLoading(true);
    
        const updateData = {
            name: formData.name.trim(),
            email: formData.email.trim(),
            phone: formData.phone.trim(),
            role_id: formData.role_id,
            photo: formData.photo,
            password: formData.password ? formData.password.trim() : null,
        };
    
        // Créez un objet FormData
        const formDataObject = new FormData();
        Object.keys(updateData).forEach((key) => {
            formDataObject.append(key, updateData[key]);
        });
    
        axios.put(`${Constants.BASE_URL}/users/${userId}`, input2)
            .then((response) => {
                console.log(response);
                setIsLoading(false);
                const userData = response.data;
                setInput2({
                    name: userData.name,
                    email: userData.email,
                    phone: userData.phone,
                    photo: null,
                    password: '',
                    role_id: userData.role_id,
                });
    
                Swal.fire({
                    position: 'top-end',
                    icon: response.data.cls,
                    title: response.data.msg,
                    showConfirmButton: false,
                    toast: true,
                    timer: 1500,
                });
    
                setModalShow(false);
                getUsers(1);
                fetchUserPermissions();
            })
            .catch((errors) => {
                setIsLoading(false);
    
                if (errors.response.status === 422) {
                    // Gérez les erreurs de validation spécifiques
                    // Vous pouvez extraire les erreurs de la réponse et les afficher
                    console.log(errors.response.data.errors);
                    setFormErrors(errors.response.data.errors);
                } else {
                    // Gérez d'autres erreurs ici
                    console.log(errors);
                }
            });
    };
    
    
    
    
    

    const getUsers = (pageNumber) =>{
        setIsLoading(true);
        axios.get(`${Constants.BASE_URL}/users?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&per_page=${input.per_page}&direction=${input.direction}`).then(res => {
            setCategories(res.data.data);
            setItemsCounterPerPage(res.data.meta.per_page);
            setStartFrom(res.data.meta.from);
            setTotalItemsCount(res.data.meta.total);
            setActivePage(res.data.meta.current_page);
            setIsLoading(false);
            console.log(res.data);
        })
    }



    const handleCreateSubmit = () => {
        
        setIsLoading(true);
    
        const formDataObject = new FormData();
    
        formDataObject.append('name', input2.name);
        formDataObject.append('email', input2.email);
        formDataObject.append('phone', input2.phone);
        formDataObject.append('photo', input2.photo);
        formDataObject.append('password', input2.password);
        formDataObject.append('role_id', input2.role_id);
    
        axios
            .post(`${Constants.BASE_URL}/users`, formDataObject)
            .then((response) => {
                setIsLoading(false);
    
                if (response.data.errors) {
                    console.log(response.data.errors);
                    setFormErrors(response.data.errors);
                    //setFormErrors(errors.response.data.errors);
                    return
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Compte créé avec succès !',
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500,
                    });
        
                    //setErrors([]);
                    // Réinitialisez les champs du formulaire après la soumission réussie
                    setInput2({
                        name: '',
                        email: '',
                        phone: '',
                        photo: null,
                        password: '',
                        role_id: '',
                    });
    
        
                    // Fermez la modal après la soumission réussie
                    setModalShow(false);
        
                    // Rechargez la liste des utilisateurs après la création
                    getUsers(1);
                    fetchUserPermissions();

                    
                }

            })
            .catch((error) => {
                setIsLoading(false);
                //console.log(error.response);
                // Gérer les erreurs
                //handleErrors(error);
            });
    };
    

    
    const getRoles = () => {
        axios.get(`${Constants.BASE_URL}/roles`)
            .then(response => {
                //console.log(response);
                setRoles(response.data.data);
            })
            .catch(error => {
                //console.error('Erreur lors de la récupération des rôles:', error);
            });
    };    

    useEffect(() => {
      getRoles();
      getUsers();
      //getUser()
      //console.log('errors'); 
      //console.log(errors); 
    }, [])

    const [modalShow, setModalShow] = useState(false);

    
    const [isLoading, setIsLoading] = useState(false);
    //const [permissions, setAttributes] = useState([]);
    const [permissions, setAttributes] = useState([]);

    const navigate = useNavigate();

    const [itemsCountPerPage, setItemsCounterPerPage] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [startFrom, setStartFrom] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [modalTitle, setModalTitle] = useState('Add');
    const [valueModalTitle, setValueModalTitle] = useState('Add');
    const [valueModalShow, setValueModalShow] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    
    const [modalValue, setModalValue] = useState([]);
    const [modalValueShow, setModalValueShow] = useState(false);


    return (
        <>
            <BreadCrumb title={'Compte Utilisateur'}/>
            {isLoading ? (<Loader />):
          
          <>
          {console.log('bloc 1 '+userPermissions.some(permission => permission.name === 'view_user_account'))}
          {userPermissions.some(permission => permission.name === 'view_user_account') ? (
        <>
          {/* Your content goes here */}
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card' style={{border:'none'}}>
                            <div className=''>
                            <div className="card-header" style={{background: 'transparent', color:'', border:'none'}}>                            
                                <div className='d-flex justify-content-between align-items-center'>
                                                                        
                                    <h4 className={'text-theme'}> 
                                        <i style={{fontSize:22}} className='fa fa-users-gear'></i> 
                                        &nbsp; Comptes Utilisateurs
                                    </h4>
                                    {userPermissions.some(permission => permission.name === 'create_user_account') && (
                                                <button className={'btn btn3'} onClick={()=>handleModal()}  style={{background:'#000f51 !important', fontWeight:'bolder', fontSize:15}}>                                                                            
                                                    <i className='fa-solid fa-user-plus'/> &nbsp;
                                                    Ajouter
                                                </button>
                                    )}                                    

                                </div>
                            </div>


                            </div>  
                            <div className='card-body'>
                                
                            <div className='search-area mb-4' style={{margin:10}}>
                                <div className='row'>
                                        <div className='col-md-4'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:0}}>mot clé</p>
                                                <input
                                                    className='form-control form-control-sm'
                                                    name={'search'}
                                                    type={'search'}
                                                    value={input.search}
                                                    onChange={handleInput}
                                                    placeholder={'saisir mot clé...'}
                                                    style={{height:'145px !important',fontSize:15}}
                                                />
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:0}}>filtrer par</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'order_by'}
                                                    value={input.order_by}
                                                    onChange={handleInput}
                                                    style={{fontSize:15}}                                                                                      
                                                >
                                                    <option value={'name'}>Nom</option>
                                                    <option value={'created_at'}>Date </option>
                                                    {/**<option value={'updated_at'}>Upda</option> */}
                                                    <option value={'role.name'}>Role</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:0}}>sens de croissance</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'direction'}
                                                    value={input.direction}
                                                    onChange={handleInput}
                                                    style={{fontSize:15}}                                                                                      
                                                >
                                                    <option value={'asc'}>ASC</option>
                                                    <option value={'desc'}>DESC</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                        <p style={{color:'#000f51', fontWeight:'bolder'}}>Compte par page</p>
                                            <label className={'w-100'}>
                                                
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'per_page'}
                                                    value={input.per_page}
                                                    onChange={handleInput}   
                                                    style={{fontSize:15}}                                                                                      
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className='d-grid' style={{marginTop:20}}>
                                                <button className={'btn btn3'} onClick={()=>getUsers(1)} style={{fontWeight:'700'}}>                                                    
                                                    Chercher
                                                </button>
                                            </div>
                                        </div>                                    
                                    {
                                        /*
                                        
                                        <div className='col-md-2'>
                                            <div className='d-grid' style={{marginTop:20}}>
                                                <button className={'btn btn-sm theme-button2'} onClick={()=>handleModal()} style={{}}>
                                                    <i className='fa-solid fa-user-plus'/> &nbsp;
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>                                                                            
                                         */
                                    }
                                    </div>
                                </div>
                                {isLoading ? <Loader/> :
                                        <div className='table-responsive soft-landing'>
                                        <table
      className={'my-table position-relative table table-hover table-striped table-bordered'}
      style={{  borderLeft: '0px solid white', borderRight: '0px solid white' }}
    >
                                                                                   <thead style={{ borderBottom: '2px solid black' }}>
                                                <tr>
                                                    <th>Nom</th>
                                                    <th>Téléphone</th>
                                                    <th>Email</th>
                                                    <th>Rôle</th>
                                                    <th>Photo</th>
                                                    {/*<th>Créer par</th>*/}
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>
                                            {Object.keys(categories).length > 0 ? categories.map((category, index) =>(
                                                    <tr key={index}>
                                                    <td>
                                                        <p className={'text-theme'}>{category.name}</p>
                                                    </td>
                                                    <td>
                                                        <p className={'text-theme'}>{category.phone}</p>                                                        
                                                    </td>
                                                    <td>
                                                        <p className={''}>{category.email}</p>                                            
                                                    </td>
                                                    <td>
                                                        <span className={'badge bg-primar rounded-pill'}>{category.role.name}</span>                                            
                                                    </td>
                                                    <td>
                                                    <img
                                                        src={buildImagePath(category.photo)}
                                                        alt={category.name}
                                                        className={'img-thumbnail table-image'}
                                                    />
                                                    </td>
                                                    {/*<td>{category.created_by}</td> */}
                                                    <td>
                                                        <p className={'text-theme'}><small>Created: {category.created_at}</small></p>
                                                        <p className={'text-theme'}><small>Updated: {category.updated_at}</small></p>                                            
                                                    </td>
                                                    <td>
                                                        {/**<button onClick={()=>{handleDetailsModal(category)}} className={'btn btn-sm btn-info my-1'}><i className='fa-solid fa-eye'/></button>**/}
                                                        {/**<Link to={`/users/edit/${category.id}`}><button className={'btn btn-sm btn-warning my-1 mx-1'}><i className='fa-solid fa-edit'/></button></Link>**/}
                                                        {/* Button with onClick handler for editing user */}

                                                        {userPermissions.some(permission => permission.name === 'edit_user_account') && (
                                                            
                                                            <button onClick={() => handleModal(category)} className={'btn btn-sm btn-warning my-1 mx-1'} style={{background:'none',border:'none', color:'#428bca', fontWeight:'500'}}>
                                                                <i className='fas fa-pen'></i>
                                                                &nbsp;
                                                                <text>modifier</text>
                                                            </button>
                                                    )}
                                                    {userPermissions.some(permission => permission.name === 'delete_user_account') && (
                                                        
                                                        <button onClick={() => { handleUserDelete(category.id) }} className={'btn btn-sm btn-danger my-1'}  style={{background:'none',border:'none', color:'#428bca', fontWeight:'500'}}>
                                                            <i className='fas fa-trash-alt'></i>
                                                            <text>supprimer</text>
                                                        </button>

                                                    )}                                                        
                                                        
                                                        
                                                    </td>
                                                </tr>                                            
                                                
                                            )): <NoDataFound/>}
                                            </tbody>
                                            
                                        </table>
                                        </div>
                                }

                            </div>   
                            <div className='card-footer'>
                                <nav className={'pagination-sm'}>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={getUsers}
                                        nextPageText={'Next'}
                                        firstPageText={'first'}
                                        prevPageText={'Previous'}
                                        lastPageText={'last'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </nav>
                            </div>                     
                        </div>
                    </div>
                </div>        


                </>
        ) : (
        // Afficher l'alerte seulement si l'utilisateur n'a pas la permission
<>
{!userPermissions.some(permission => permission.name === 'view_user_account') && (
<>
<div className='alert alert-danger' role='alert' style={{display:''}}>
    Vous n'avez pas le droit d'accéder à ce contenu.
  </div>
</>
)}

</>
      )}    

          </>
          }        
                  


<Modal show={modalShow} onHide={handleCloseModal}>
    <Modal.Header closeButton>
        <Modal.Title> {modalTitle} Un Compte</Modal.Title>
    </Modal.Header>
    <Modal.Body>

    <label className={`w-100 ${errors && errors.role_id !== undefined ? 'mb-4' : ''}`}>
    <p>Sectionnez un rôle</p>
    <select
        className={errors && errors.role_id !== undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
        name={'role_id'}
        value={input2.role_id}
        onChange={handleInput2}
        //onChange={(e) => setFormData({ ...formData, role_id: e.target.value })}
    >
        <option value={''}>Sélectionnez un rôle</option>
        {Array.isArray(roles) && roles.map(role => (
            <option key={role.id} value={role.id}>
                {role.name}
            </option>
        ))}
    </select>
    {formErrors && formErrors.role_id && formErrors.role_id.length > 0 && (
                <p className={'login-error-msg'} style={{ marginBottom: 20 }}>
                    <small>{formErrors.role_id[0]}</small>
                    <br/>                    
                </p>
            )}                

</label>
    {formErrors && formErrors.role_id && formErrors.role_id.length > 0 && (
        <>
        <br/><br/>
        </>
            )}                


        <label className={`w-100 ${formErrors && formErrors.name !== undefined ? 'mb-4' : ''}`}>
            <p>Entrez Un Nom:</p>
            <input
                className={formErrors && formErrors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                type={'text'}
                name={'name'}
                value={input2.name}
                onChange={handleInput2}
                //onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                placeholder={''}
            />
            {formErrors && formErrors.name && formErrors.name.length > 0 && (
                <p className={'login-error-msg'} style={{ marginBottom: 20 }}>
                    <small>{formErrors.name[0]}</small>
                </p>
            )}            
        </label>


        <label className={`w-100 ${formErrors && formErrors.email !== undefined ? 'mb-4' : ''}`}>
            <p>Entrez Une Adresse Mail:</p>
            <input
                className={formErrors && formErrors.email !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                type={'email'}
                name={'email'}
                value={input2.email}
                onChange={handleInput2}
                //onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                placeholder={''}
            />
            {formErrors && formErrors.email && formErrors.email.length > 0 && (
                <p className={'login-error-msg'} style={{ marginBottom: 20 }}>
                    <small>{formErrors.email[0]}</small>
                </p>
            )}            
        </label>


        <label className={`w-100 ${formErrors && formErrors.password !== undefined ? 'mb-4' : ''}`}>
            <p>Entrez Un Mot de Passe:</p>
            <input
                className={formErrors && formErrors.password !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                type={'password'}
                name={'password'}
                value={input2.password}
                onChange={handleInput2}
                //onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                placeholder={''}
            />
            {formErrors && formErrors.password && formErrors.password.length > 0 && (
                <p className={'login-error-msg'} style={{ marginBottom: 20 }}>
                    <small>{formErrors.password[0]}</small>
                </p>
            )}            
        </label>


        <label className={`w-100 ${formErrors && formErrors.phone !== undefined ? 'mb-4' : ''}`}>
        <p>Entrez votre Téléphone</p>
            <input
                className={formErrors && formErrors.phone !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                type={'text'}
                name={'phone'}
                value={input2.phone}
                onChange={handleInput2}
                //onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                placeholder={''}
            />
            {formErrors && formErrors.phone && formErrors.phone.length > 0 && (
                <p className={'login-error-msg'} style={{ marginBottom: 20 }}>
                    <small>{formErrors.phone[0]}</small>
                </p>
            )}            
        </label>


            <label className={`w-100 ${errors && errors.photo !== undefined ? 'mb-4' : ''}`}>
                <p>Entrez Une Photo</p>
                <input
                    className={errors && errors.photo !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                    type={'file'} 
                    name={'photo'}
                    //onChange={handleInput2}
                    onChange={handleLogo}
                    //onChange={(e) => setFormData({ ...formData, photo: e.target.files[0] })}
                    required={true}
                />
            {formErrors && formErrors.photo && formErrors.photo.length > 0 && (
                <p className={'login-error-msg'} style={{ marginBottom: 20 }}>
                    <small>{formErrors.photo[0] != undefined ? formErrors.photo[0] : null}</small>
                </p>
            )}            
            </label>
            {input2.photoPreview !== undefined || input2.photo_preview !== undefined?
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='photo-preview mt-3'>                                            
                                                <img alt={'Photo preview'} src={input2.photoPreview == undefined ? input2.photo_preview : input2.photoPreview } className={'img-thumbnail aspect-one'} />
                                            </div>
                                        </div>
                                    </div> : null
                                }            


{/***            <Button variant="primary" className={'theme-button mt-4 float-end'} onClick={handleCreateSubmit}>
                Créer le Compte
            </Button> */}

            <button
                style={{fontSize:17, fontWeight:'bolder'}}
                className={'btn btn3 float-end mt-4'} 
                onClick={isEditMode ? ()=> handleUserUpdate(userId):handleCreateSubmit } dangerouslySetInnerHTML={{ __html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...' : `${modalTitle} Compte` }}/>            

    </Modal.Body>
</Modal>






        </>
    );
};
export default User;
