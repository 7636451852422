import React from 'react';
import {Link} from 'react-router-dom';


const SideBar = () => {



    return (
        <div id="layoutSidenav_nav">
        <nav class="sb-sidenav accordion bg-theme-basic" id="sidenavAccordion">
            <div class="sb-sidenav-menu">

            <div class="nav">

                    <div class="sb-sidenav-menu-heading" style={{marginBottom:-60}}><i class="fa-brands fa-cpanel" style={{fontSize:90, color:'white'}}/></div>
                    {/*<div class="sb-sidenav-menu-heading"></div> */}
                    {/* <div style={{height:1,background:'white', marginTop:25, marginBottom:-30}}></div> */}
                    <Link class="nav-link" to="/" style={{marginTop:35, fontSize:16}}>
                        {/*<div class="sb-nav-link-icon"><i class="fas fa-dashboard"></i></div> */}
                        Tableau de Bord
                    </Link>

                    {/*<div class="sb-sidenav-menu-heading">Modules</div> */}
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts" style={{fontSize:16}}>
                        {/* <div class="sb-nav-link-icon"><i class="fas fa-lock"></i></div> */}
                        <div class="sb-sidenav-collapse-arrow" style={{marginLeft:0}}><i class="fas fa-angle-down"></i></div>
                         &nbsp;
                         Permissions
                         &nbsp;
                         <div class="sb-nav-link-icon"><i class="fas fa-lock" style={{fontSize:13}}></i></div>
                         {/* <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div> */}
                    </a>
                    <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" style={{fontSize:16}}>
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/permissions/">Gérer Permissions</Link>
                        </nav>
                    </div>
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#sub_category" aria-expanded="false" aria-controls="sub_category" style={{fontSize:16}}>
                        
                         <div class="sb-sidenav-collapse-arrow" style={{marginLeft:0, fontSize:16}}><i class="fas fa-angle-down"></i></div>
                         &nbsp;
                         Rôles
                         &nbsp;
                         <div class="sb-nav-link-icon"><i class="fas fa-people-group" style={{fontSize:13}}></i></div>
                        
                    </a>
                    <div class="collapse" id="sub_category" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionSubCategorie">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/roles/" style={{fontSize:16}}>Gérer Les Rôles</Link>
                        </nav>
                    </div>

                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#visibility" aria-expanded="false" aria-controls="visibility" style={{fontSize:16}}>
                        
                        <div class="sb-sidenav-collapse-arrow" style={{marginLeft:0}}><i class="fas fa-angle-down"></i></div>
                        &nbsp;
                        Visibilité Dossiers
                        &nbsp;
                        <div class="sb-nav-link-icon"><i class="fas fa-folder" style={{fontSize:13}}></i></div>
                        
                    </a>
                    <div class="collapse" id="visibility" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionVisibility">
                    <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/visibilite/" style={{fontSize:16}}>Gérer Visibilite</Link>
                        </nav>
                    </div>
                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#brand" aria-expanded="false" aria-controls="brand" style={{fontSize:16}}>                        
                        <div class="sb-sidenav-collapse-arrow" style={{marginLeft:0}}><i class="fas fa-angle-down"></i></div>
                        &nbsp;
                        Utilisateurs
                        &nbsp;
                        <div class="sb-nav-link-icon"><i class="fas fa-users" style={{fontSize:13}}></i></div>                       
                    </a>
                    <div class="collapse" id="brand" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionbrand">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/users" style={{fontSize:16}}>Créer Utilisateurs</Link>
                        </nav>
                    </div>

                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#projet" aria-expanded="false" aria-controls="projet" style={{fontSize:16}}>
                        
                        <div class="sb-sidenav-collapse-arrow" style={{marginLeft:0}}><i class="fas fa-angle-down"></i></div>
                        &nbsp;
                        Projets attribués
                        &nbsp;
                        <div class="sb-nav-link-icon"><i class="fas fa-folder" style={{fontSize:13}}></i></div>  
                        
                    </a>
                    <div class="collapse" id="projet" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionprojet" style={{fontSize:16}}>
                        <nav class="sb-sidenav-menu-nested nav" style={{fontSize:16}}>
                        <Link class="nav-link" to="/documents" style={{fontSize:16}}>Gérer Projet</Link>
                        </nav>
                    </div>

                    {/*
                    <Link class="nav-link" to="product-attributes">
                        <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                        Paramètres
                    </Link>
                     */}
                </div>


            </div>
            <div class="sb-sidenav-footer bg-theme text-silver">
                <div class="small">connecté en tant que:</div>
                {localStorage.name != undefined ? localStorage.name : null}
            </div>
        </nav>
    </div>
    );
};

export default SideBar;