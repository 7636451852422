import React from 'react';
import {Helmet} from "react-helmet";

const BreadCrumb = (props) => {
    return (
        <>
            <Helmet>
                <title>{props.title} | CPMS</title>
            </Helmet>
            <ol class="breadcrumb mb-4 mt-4">
                <li class="breadcrumb-item active text-theme-light">Dashboard</li>
                <li class="breadcrumb-item active"><i className='fa fa-list'></i> {props.title}</li>
            </ol>            
        </>
    );
};

export default BreadCrumb;