import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../partials/BreadCrumb';
import CardHeader from '../../partials/miniComponent/CardHeader/CardHeader';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Constants from '../../../Constants';
import Swal from 'sweetalert2';
import NoDataFound from '../../partials/miniComponent/NoDataFound';
import Loader from '../../partials/miniComponent/Loader';
import Pagination from 'react-js-pagination';
import { getUserPermissions } from '../../../services/authService';

const RoleList = (props) => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    //const [input, setInput] = useState({ name: '' });
    const [input, setInput] = useState({
        order_by: 'created_at',
        per_page: 10,
        direction: 'desc',
        search: '',
    });
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //const [attributes, setAttributes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const navigate = useNavigate();

    const [itemsCountPerPage, setItemsCounterPerPage] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [startFrom, setStartFrom] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [modalTitle, setModalTitle] = useState('Add');
    const [valueModalTitle, setValueModalTitle] = useState('Add');
    const [valueModalShow, setValueModalShow] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    
    const [modalValue, setModalValue] = useState([]);
    const [modalValueShow, setModalValueShow] = useState(false);
    

        const fetchUserPermissions = async () => {
          try {
            const permissions = await getUserPermissions();
            setUserPermissions(permissions);
            //console.log(userPermissions)
          } catch (error) {
            console.error('Error fetching user permissions:', error);
          }
        };
    

      useEffect(() => {
        fetchUserPermissions();
      }, []);

    const updatePermissionListAfterDelete = (attributeId) => {
        // Filtrer les catégories pour exclure celle supprimée
        const updatedAttributes = attributes.filter(attribute => attribute.id !== attributeId);
        setAttributes(updatedAttributes);
      };

    const handleValueDetailsModal = (value) =>{
        //console.log(value);
        setModalValue(value);
        setModalValueShow(true);
    }

    const handlePermissionCreate = () =>{
        console.log(input);
        setIsLoading(true);

         // Créez un objet contenant les données à envoyer au serveur, y compris les permissions
        const roleData = {
            name: input.name,
            permissions: selectedPermissions
        };

        axios.post(`${Constants.BASE_URL}/roles`, roleData)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);

            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });

            setErrors([]);
            setInput({ name: '' });
            setModalShow(false);
            getAttributes();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });
    }

    const handleValueCreate = () =>{
        
        console.log(input);
        setIsLoading(true);
        axios.post(`${Constants.BASE_URL}/roles`, input)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });
    
            setErrors([]);
            setInput({ name: '' }); // Réinitialiser le champ "name"
            setModalShow(false);
            getAttributes();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });
    

    }


    const handleValueEdit = (id) =>{
        console.log(input);
        setIsLoading(true);
        axios.put(`${Constants.BASE_URL}/roles/${input.id}`, input)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });
    
            setErrors([]);
            setInput({ name: '' });
            setValueModalShow(false);
            //setModalShow(false);
            getAttributes();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });

    }


    const handleRoleDelete = (id) => {

        Swal.fire({
            title: 'Etes-vous sûr?',
            text: "Ce Rôle sera supprimé !",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#000f51',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constants.BASE_URL}/roles/${id}`).then(res=>{
                    updatePermissionListAfterDelete(id); // Mettre à jour la liste après la suppression
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.msg,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500
                    })
           
                });
            }
          })

    }
    


    const handleModal =(attribute = undefined)=>{    
        //setInput({status:1});
        if (attribute != undefined) {
            console.log(attribute,'if');
            setModalTitle('Modifier');
            setIsEditMode(true);
            setInput({ name: attribute.name, id:attribute.id });
            // Sélectionnez les permissions du rôle
            setSelectedPermissions(attribute.permissions.map(permission => permission.id));

        }else{
            console.log('else');
            setIsEditMode(false);
            setModalTitle('Ajouter');
            setInput({ name:'' });
            // Réinitialisez les permissions sélectionnées lors de l'ajout
            setSelectedPermissions([]);

        }
        setErrors([]);
        setModalShow(true);                    
    }

    const handlePermissionCheckbox = (permissionId) => {
        // Mettez à jour la liste des permissions sélectionnées
        setSelectedPermissions((prevPermissions) => {
          if (prevPermissions.includes(permissionId)) {
            // Si la permission est déjà sélectionnée, la retirez
            return prevPermissions.filter((id) => id !== permissionId);
          } else {
            // Sinon, ajoutez-la
            return [...prevPermissions, permissionId];
          }
        });
      };

    const handlePermissionUpdate = (id) =>{
        console.log(input);
        setIsLoading(true);
        // Créez un objet contenant les données à mettre à jour, y compris les permissions
        const updatedRole = {
            ...input,
            permissions: selectedPermissions
        };

        axios.put(`${Constants.BASE_URL}/roles/${id}`, updatedRole)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });
            //console.log('updatedRole')
            //console.log(updatedRole)
            setErrors([]);
            setModalShow(false);
            getAttributes();
            fetchUserPermissions();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });
    

    }

    const handleInput = (e) => { setInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));};
    
    const getPermissions = async () => {
        try {
            const response = await axios.get(`${Constants.BASE_URL}/all-permissions`);
            return response.data.data;
        } catch (error) {
            console.error("Error fetching permissions:", error);
            return [];
        }
    };
    
    // Nouvelle fonction pour charger les permissions
    const fetchPermissions = async () => {
        const fetchedPermissions = await getPermissions();
        setPermissions(fetchedPermissions);
    };

    useEffect(() => {
        // Chargez les permissions lors du rendu initial
        fetchPermissions();
    }, []);

    const getAttributes = (pageNumber = 1) => {
        setIsLoading(true);        
        axios.get(`${Constants.BASE_URL}/roles?page=${pageNumber}&search=${input.search || ''}&order_by=${input.order_by || ''}&per_page=${input.per_page || ''}&direction=${input.direction || ''}`).then(
            res => {
                setIsLoading(false);
                //console.log(res.data.data);
                setAttributes(res.data.data);
                setItemsCounterPerPage(res.data.meta.per_page);
                setStartFrom(res.data.meta.from);
                setTotalItemsCount(res.data.meta.total);
                setActivePage(res.data.meta.current_page);
            }
        ).catch(error => {
            setIsLoading(false);
            console.error("Error fetching roles:", error);
        });
    };

    useEffect(() => {
        getAttributes();
    }, []);

    useEffect(() => {
        // Ce code sera exécuté à chaque mise à jour de attributes
        console.log(attributes);
        console.log('isEditMode');
        console.log(isEditMode);
    }, [attributes]);  // Utilisez attributes comme dépendance ici



    return (
        <>
          <BreadCrumb title={'Rôles'}/>  
          {isLoading ? (<Loader />):
          <>

          {console.log('bloc 1 '+userPermissions.some(permission => permission.name === 'view_role'))}
          {console.log('bloc create '+userPermissions.some(permission => permission.name === 'create_role'))}
          {userPermissions.some(permission => permission.name === 'view_role') ? (
        <>
          <div className='row'>
                <div className='col-md-12'>
                <div className='card' style={{border:'none'}}>
                    <div className="card-header" style={{background: 'transparent', color:'', border:'none'}}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h4 className={'text-theme'}><i className='fa fa-user-group' style={{fontSize:20}}></i> Rôles - Actions sur les Projets</h4>
                                    <Link to={props.link}>
                                    {userPermissions.some(permission => permission.name === 'create_role') && (
                                        <button className={'btn btn3'} onClick={()=>handleModal()} style={{background:'#000f51 !important', fontWeight:'bolder', fontSize:15}}>                                                                            
                                            Ajouter {props.button_text} 
                                        </button>
                                    )}                                    
                                    </Link>
                            </div>
                        </div>

                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                <div className='search-area mb-4' style={{margin:10}}>
                                    <div className='row '>
                                        <div className='col-md-3'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:10}}>mot clé</p>
                                                <input
                                                    className='form-control form-control-sm'
                                                    name={'search'}
                                                    type={'search'}
                                                    value={input.search}
                                                    onChange={handleInput}
                                                    placeholder={'saisir mot clé...'}
                                                    style={{height:'145px !important', fontSize:15}}
                                                />
                                            </label>
                                        </div>
                                        <div className='col-md-3'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:10}}>filtrer par</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'order_by'}
                                                    value={input.order_by}
                                                    onChange={handleInput}
                                                    style={{fontSize:15}}
                                                >
                                                    <option value={'name'}>Nom des rôles</option>
                                                    <option value={'created_at'}>Date</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:10}}>sens de croissance</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'direction'}
                                                    value={input.direction}
                                                    onChange={handleInput}
                                                    style={{fontSize:15}}
                                                >
                                                    <option value={'asc'}>ASC</option>
                                                    <option value={'desc'}>DESC</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder', marginBottom:10}}>Enregistr. par page</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'per_page'}
                                                    value={input.per_page}
                                                    onChange={handleInput}
                                                    style={{fontSize:15}}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='col-md-1'>
                                            <div className='d-grid' style={{marginTop:32}}>
                                                <button className={'btn btn3'} onClick={()=>getAttributes(1)} style={{fontWeight:'700'}}>                                                    
                                                    Chercher
                                                </button>
                                            </div>
                                        </div>                                    
                                    {
                                        /*
                                        
                                        <div className='col-md-2'>
                                            <div className='d-grid' style={{marginTop:20}}>
                                                <button className={'btn btn-sm theme-button2'} onClick={()=>handleModal()} style={{}}>
                                                    <i className='fa-solid fa-user-plus'/> &nbsp;
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>                                                                            
                                         */
                                    }
                                    </div>
                                </div>
                                </div>                                



                                {isLoading ? <Loader/> :
                                        <div className='table-responsive soft-landing'>
                                        <table
      className={'my-table position-relative table table-hover table-striped table-bordered'}
      style={{  borderLeft: '0px solid white', borderRight: '0px solid white' }}
    >
                                            <thead style={{ borderBottom: '2px solid black' }}>
                                                <tr>
                                                    <th>Nom du Rôle</th>                                                    
                                                    <th>Permissions </th>
                                                    <th>Créé par</th>
                                                    {/*<th>Date Heure</th> */}
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>                                            
                                            {attributes && attributes.length > 0 ? attributes.map((attribute, index) =>(
                                                    <tr key={index}>
                                                    <td>
                                                        <b>{attribute.name}</b>
                                                    </td>
                                                    <td style={{width:'50%'}}>
                                                        {attribute.permissions.map((permission, idx) => (
                                                            <span style={{ marginRight: 5, fontSize: 12, margin:5 }} className='badge bg-primar rounded-pill' key={idx}>{permission.name}{idx < attribute.permissions.length - 1 ? ', ' : ''}</span>
                                                        ))}
                                                    </td>
                                                    <td> 
                                                        <i className='fa fa-user'></i><br/>
                                                        {attribute.created_by}
                                                    </td>
{/*
                                                    <td>
                                                        <p className={'text-theme'}><small>Created: {attribute.created_at}</small></p>
                                                        <p className={'text-theme'}><small>Updated: {attribute.updated_at}</small></p>                                            
                                                    </td>
 */}

                                                    <td>
                                                    {userPermissions.some(permission => permission.name === 'edit_role') && (

                                                        <button onClick={() => handleModal(attribute)} className={'btn btn-sm btn-warning my-1 mx-1'} style={{background:'none',border:'none', color:'#428bca', fontWeight:'500'}}>
                                                            <i className='fas fa-pen'></i>
                                                            &nbsp;
                                                            <text>modifier</text>
                                                        </button>
                                                        
                                                    )}
                                                    {userPermissions.some(permission => permission.name === 'delete_role') && (

                                                        <button onClick={() => { handleRoleDelete(attribute.id) }} className={'btn btn-sm btn-danger my-1'}  style={{background:'none',border:'none', color:'#428bca', fontWeight:'500'}}>
                                                            <i className='fas fa-trash-alt'></i>
                                                            <text>supprimer</text>
                                                        </button>

                                                    )}                                                    
                                                        
                                                    </td>
                                                </tr>                                            
                                                
                                            )): <NoDataFound/>}
                                            </tbody>
                                            
                                        </table>
                                        </div>
                                }

                            </div>
                        </div>
                        <div className='card-footer'>
                                <nav className={'pagination-sm'}>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={getAttributes}
                                        nextPageText={'Next'}
                                        firstPageText={'first'}
                                        prevPageText={'Previous'}
                                        lastPageText={'last'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </nav>
                            </div>                            
                    </div>
                </div>
            </div>              
            </>
        ) : (
        // Afficher l'alerte seulement si l'utilisateur n'a pas la permission
<>
{!userPermissions.some(permission => permission.name === 'view_role') && (
<>
<div className='alert alert-danger' role='alert' style={{display:''}}>
    Vous n'avez pas le droit d'accéder à ce contenu.
  </div>
</>
)}

</>
      )}    

          </>
          }        
          

            <Modal
            centered
            show={modalShow}
            onHide={()=>setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-center" style={{fontSize:19}}>
                        {modalTitle} un Rôle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                                    <label className={'w-100'}>
                                        <p>Nom du Rôle</p>
                                        <input
                                            className={errors && errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'name'}
                                            value={input.name}
                                            onChange={handleInput}
                                            placeholder={'Saisir le mom du Rôle'}
                                            style={{fontSize:16}}
                                        />

                                        <p className={'login-error-msg'}>
                                            <small>
                                                {errors && errors.name != undefined ? errors.name[0] : null}
                                            </small>
                                        </p>

                                    </label>
                                    <div className="permissions-checkbox" style={{lineHeight:'33px'}}>
                                        {/* Affichez la liste des permissions avec des cases à cocher */}
                                        {permissions.map((permission) => (
                                        <div key={permission.id}>
                                            <label>
                                            <input
                                                type="checkbox"
                                                value={permission.id}
                                                checked={selectedPermissions.includes(permission.id)}
                                                onChange={() => handlePermissionCheckbox(permission.id)}
                                            />{' '}
                                            {permission.name}
                                            </label>
                                        </div>
                                        ))}
                                    </div>                                    
                                    <button
                                        className={'btn btn3 mt-4 float-end'} 
                                        style={{fontSize:16, fontWeight:'bolder'}}
                                        onClick={isEditMode ? ()=> handlePermissionUpdate(input.id):handlePermissionCreate } dangerouslySetInnerHTML={{ __html: isLoading
                                        ? 
                                        '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...' 
                                        : 
                                        `${modalTitle} Rôle` }}                                            
                                        />

                </Modal.Body>

            </Modal>   

            <Modal
            centered
            show={valueModalShow}
            onHide={()=>setValueModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalTitle} Attribute Value
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                                    <label className={'w-100'}>
                                        <p>Name</p>
                                        <input
                                            className={errors && errors.name !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'name'}
                                            value={input.name}
                                            onChange={handleInput}
                                            placeholder={'Enter Attribute Name'}
                                        />

                                        <p className={'login-error-msg'}>
                                            <small>
                                                {errors && errors.name != undefined ? errors.name[0] : null}
                                            </small>
                                        </p>

                                    </label>
                                
                                    <button 
                                        className={'btn theme-button mt-4'} 
                                        onClick={isEditMode ? handleValueEdit : handleValueCreate}                                             
                                        dangerouslySetInnerHTML={{ __html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...' : `${modalTitle} Value` }}/>

                </Modal.Body>

            </Modal>   

            <Modal
                centered
                show={modalValueShow}
                onHide={()=>setModalValueShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Value Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <table className={'table table-bordered table-hover table-striped'}>
                            <tr>
                                <th>ID</th>
                                <td>{modalValue.id}</td>
                            </tr>                            
                            <tr>
                                <th>Name</th>
                                <td>{modalValue.name}</td>
                            </tr>                            
                            <tr>
                                <th>Status</th>
                                <td>{modalValue.status}</td>
                            </tr>                            
                            <tr>
                                <th>Created By</th>
                                <td>{modalValue.created_by}</td>
                            </tr>                            
                            <tr>
                                <th>Created at</th>
                                <td>{modalValue.created_at}</td>
                            </tr>                            
                            <tr>
                                <th>ID</th>
                                <td>{modalValue.id}</td>
                            </tr>                            
                            <tr>
                                <th>Updated at</th>
                                <td>{modalValue.updated_at}</td>
                            </tr>                            
                        </table>
                </Modal.Body>

            </Modal>   
        </>
    );
};

export default RoleList;