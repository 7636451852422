// ProjectRouter.js
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Master from '../layouts/Master';
import Dashboard from '../modules/Dashboard';
import Error500 from '../modules/Error500';
import Test from '../modules/Test';
import Profile from '../modules/profil/Profile';
import DocumentsPage from '../modules/Document/DocumentsPage';
import FolderDetails from '../FolderDetails';
import PermissionList from '../modules/permission/PermissionList';
import RoleList from '../modules/role/RoleList';
import User from '../modules/user/User';
import Visibility from '../modules/visibility/Visibility';


const ProjectRouter = createBrowserRouter([
  {
    path: '/',
    element: <Master />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/profil',
        element: <Profile />,
      },
      {
        path: '/documents',
        element: <DocumentsPage />,
      },    
      {
        path: '/documents/details/:id',
        element: <FolderDetails />,
      },
      {
        path: '/documents/details/:id/:name', // Ajoutez la route pour les détails du dossier avec un paramètre d'ID et de nom
        element: <FolderDetails />,
      },    
    {
      path: '/permissions',
      element: <PermissionList/>
    },
    {
      path: '/roles',
      element: <RoleList/>
    },
    {
      path: '/visibilite',
      element: <Visibility/>
    },
    {
      path: '/users',
      element: <User/>
    },
    {
      path: '/error-500',
      element: <Error500 />,
    },
    {
      path: '/test',
      element: <Test />,
    },
    ],
  },
  
]);

export default ProjectRouter;
