import React, { useEffect, useState } from "react";
import axios from "axios";


import Constants from "../Constants";
import { useNavigate } from "react-router-dom";
import FolderForm from "./FolderForm";
import folderfill from "../assets/img/folder-fill.png";
import folderempty from "../assets/img/folder-empty.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { textFilter } from "react-bootstrap-table2-filter";
import ProgressBar from "react-bootstrap/ProgressBar";
import filterFactory from "react-bootstrap-table2-filter";
import { getUserPermissions } from "../services/authService";

//import { ContextMenuTrigger } from 'react-contextmenu';
//import CustomContextMenu from './modules/contextMenu/ContextMenu';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Loader from "./partials/miniComponent/Loader";



const WithLabelExample = () => {
  const now = 80;
  return <ProgressBar now={now} label={`${now}%`} />;
};

const FolderList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [taskFilter, setTaskFilter] = useState("");
  const [show, setShow] = useState(false);
  const [showw, setShoww] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [showAlert, setShowAlert] = useState('');//!userPermissions.some((permission) => permission.name === "view_project")

  useEffect(() => {
    setShowAlert(userPermissions.some((permission) => permission.name === "view_project"));
  }, [userPermissions]);

  
  const handleClosee = () => setShoww(false);
  const handleShoww = () => {
    //console.log("clicked");
    setShoww(true);
  };
  let alertStyle = { display: "none" };

  const fetchUserPermissions = async () => {
    try {
      const permissions = await getUserPermissions();
      setUserPermissions(permissions);
      //console.log(userPermissions)
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  useEffect(() => {
    fetchUserPermissions();
  }, []);



  const handleClose = () => setShow(false);

  const handleShow = (taskId) => {
    const taskToEdit = folderContent.find((task) => task.id === taskId);
    setEditedTask(taskToEdit);
    //console.log('coooooo');
    setShow(true);
  };

  // Ajoutez ces variables d'état au début de votre fonction de composant
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);

  const [editedTask, setEditedTask] = useState("");
  const [itemName, setItemName] = useState("");

  const [taskName, setTaskName] = useState("");
  const [taskStatus, setTaskStatus] = useState("en_cours"); // Mettez en minuscules

  const [folderTasksUpdated, setFolderTasksUpdated] = useState(false);
  const [projectName, setProjectName] = useState("");

  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Nombre d'éléments à afficher par page
  const [filterName, setFilterName] = useState("");
  const [viewMode, setViewMode] = useState("grid"); // 'grid' ou 'list'
  const [contextMenuFolder, setContextMenuFolder] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [modificationSuccess, setModificationSuccess] = useState(false); // Nouvel état pour indiquer le succès de la modification
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [folderTasks, setFolderTasks] = useState([]);
  const [folderContent, setFolderContent] = useState([]);
  // Ajoutez cette fonction pour ouvrir la fenêtre modale de mise à jour de la tâche
  const openEditTaskModal = (taskId) => {
    // Trouvez la tâche avec l'ID donné dans folderTasks
    const taskToEdit = folderTasks.find((task) => task.id === taskId);
    setEditedTask(taskToEdit);
    //console.log(taskToEdit);
    //console.log(editedTask);
    setIsEditTaskModalOpen(true);
  };

  // Ajoutez cette fonction pour fermer la fenêtre modale de mise à jour de la tâche
  const closeEditTaskModal = () => {
    setEditedTask(null);
    setIsEditTaskModalOpen(false);
  };

  // Mettez à jour la fonction handleEditTask
  const handleEditTask = (taskId) => {
    openEditTaskModal(taskId);
    console.log(`Édition de la tâche ${taskId}`);
  };

  async function getFolderIdByTaskId(taskId) {
    try {
      const response = await axios.get(
        `${Constants.BASE_URL}/tasks/${taskId}/folder`
      );
      return response.data.folderId;
    } catch (error) {
      console.error("Error fetching folderId:", error);
      throw error;
    }
  }

  const handleTaskUpdate = async () => {
    try {
      // Vérifiez si editedTask est défini
      if (!editedTask) {
        console.error("Aucune tâche en cours d'édition.");
        return;
      }

      const response = await axios.put(
        `${Constants.BASE_URL}/tasks/${editedTask.id}`,
        {
          // Ajoutez les champs de données que vous souhaitez mettre à jour
          name: editedTask.name,
          status: editedTask.status,
          // Ajoutez d'autres champs au besoin
        }
      );

      // Vérifiez la réponse de l'API pour le succès de la mise à jour
      if (response.status === 200) {
        //console.log("Tâche mise à jour avec succès");

        // Fermez la fenêtre modale après la mise à jour de la tâche
        closeEditTaskModal();
        setShow(false);

        // Utilisez await pour obtenir le résultat de la fonction asynchrone
        const folderId = await getFolderIdByTaskId(editedTask.id);
        //console.log("FolderId:", folderId);

        fetchFolderTasks(folderId);
        console.log("old");
        //console.log(folderTasks);

        //console.log(fetchFolderTasks(folderId));

        // Affichez une alerte de succès avec Swal
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Tâche mise à jour avec succès",
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });

        setFolderTasksUpdated(false);
      } else {
        console.error(
          "Échec de la mise à jour de la tâche. Réponse de l'API non réussie."
        );
        // Gérez l'erreur selon vos besoins (affichage d'une alerte, journalisation, etc.)
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche", error);
      // Gérez l'erreur selon vos besoins (affichage d'une alerte, journalisation, etc.)
    }
  };

  // Créez le composant TaskUpdateModal

  const openTaskModal = () => {
    setIsTaskModalOpen(true);
  };
  const closeTaskModal = () => {
    setIsTaskModalOpen(false);
    //console.log(folders);
    //window.location.reload();
    refreshFolders();
  };

  const handleDeleteTask = async (taskId) => {
    try {
      Swal.fire({
        title: "Etes-vous sûr?",
        text: "Cette Tâche sera supprimée !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000f51",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Envoyer une requête de suppression au serveur
          axios.delete(`${Constants.BASE_URL}/tasks/${taskId}`);

          // Mettre à jour l'état local des tâches dans le dossier (supprimer la tâche supprimée)
          setFolderContent((prevTasks) =>
            prevTasks.filter((task) => task.id !== taskId)
          );
          //setFolderContent();

          // Affichez une alerte de succès avec Swal
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Tâche supprimée avec succès",
            showConfirmButton: false,
            toast: true,
            timer: 1500,
          });
        }
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de la tâche", error);

      // Affichez une alerte d'erreur avec Swal
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Erreur lors de la suppression de la tâche",
        showConfirmButton: false,
        toast: true,
        timer: 1500,
      });
    }
  };

  // Render the TaskModal component

  const fetchFolderTasks = async (folderId) => {
    try {
      const response = await axios.get(
        `${Constants.BASE_URL}/folders/${folderId}/tasks`
      );
      // Mettez à jour l'état local des tâches associées au dossier
      setFolderContent("response.data.tasks");
      setFolderTasksUpdated(true); // Indiquez que les tâches du dossier ont été mises à jour
      //console.log(`voici`);
      //console.log(response.data.tasks);
      setFolderContent(response.data.tasks);
      //console.log(folderContent);
      //console.log(folderTasks);
      //setFolderContent(response.data.tasks);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des tâches du dossier",
        error
      );
    }
  };

  const navigate = useNavigate();
  const switchToGridView = () => {
    setViewMode("grid");
  };

  const openModal = () => {
    setIsModalOpen(true);
    setNewFolderName(contextMenuFolder.name);
  };

  const handleAddTask = async () => {
    try {
      // Effectuez l'ajout de la tâche côté serveur avec l'API appropriée
      const response = await axios.post(
        `${Constants.BASE_URL}/folders/${contextMenuFolder.id}/tasks`,
        {
          name: taskName,
          status: taskStatus,
        }
      );

      // Mettez à jour l'état local des tâches dans le dossier
      setFolderContent((prevContent) => {
        const newContent = [...(prevContent || []), response.data.task];
        return newContent;
      });

      // Réinitialisez les champs du formulaire
      setTaskName("");
      setTaskStatus("en_cours");
      fetchUserPermissions();
      // Affichez une alerte de succès avec Swal
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Tâche ajoutée avec succès",
        showConfirmButton: false,
        toast: true,
        timer: 1500,
      });

      // Fermez la fenêtre modale après l'ajout de la tâche
      //closeTaskModal();
      handleClosee();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la tâche", error);

      // Affichez une alerte d'erreur avec Swal
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Erreur lors de l'ajout de la tâche",
        showConfirmButton: false,
        toast: true,
        timer: 1500,
      });
    }
  };

  const handleModifyTasks = async () => {
    // Appeler la fonction pour récupérer les tâches associées au dossier
    await fetchFolderTasks(contextMenuFolder.id);
    // Masquer le dropdown après l'action
    hideDropdown();
  };

  const updateFolderName = async () => {
    try {
      // Effectuez la mise à jour du nom du dossier côté serveur avec l'API appropriée
      const updatedFolder = await axios.put(
        `${Constants.BASE_URL}/update-folder-name/${contextMenuFolder.id}`,
        {
          new_name: newFolderName,
        }
      );

      // Mettez à jour l'état local des dossiers avec le dossier mis à jour
      setFolders((prevFolders) =>
        prevFolders.map((folder) =>
          folder.id === contextMenuFolder.id ? updatedFolder.data : folder
        )
      );
      // Indiquez que la modification a réussi
      setModificationSuccess(true);

      // Fermez la fenêtre modale
      setIsModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom du dossier", error);
    }
  };

  useEffect(() => {
    //console.log('folderContent')
    //console.log(folderContent)
    //console.log('folderTasks')
    //console.log(folderTasks)
    //console.log("currentFolders")
    //console.log(currentFolders)
    fetchUserPermissions();
    if (modificationSuccess) {
      // Actualisez la page ici
      refreshFolders();

      // Réinitialisez l'état pour éviter une actualisation continue
      setModificationSuccess(false);
    }
  }, [modificationSuccess]);

  const CustomContextMenuButton = ({ onContextMenuClick, folder }) => {
    const handleButtonClick = (e) => {
      e.preventDefault();
      onContextMenuClick(folder);
    };

    return (
      <button onClick={handleButtonClick}>Ouvrir le menu contextuel</button>
    );
  };

  const handleEditFolder = (folder) => {
    // Logique pour l'édition du dossier, par exemple, afficher un formulaire d'édition
    //console.log(`Édition du dossier ${folder.name}`);
  };

  const handleDeleteFolder = async (folder) => {
    // Logique pour la suppression du dossier, par exemple, afficher une confirmation
    //console.log(`Suppression du dossier ${folder.name}`);

    try {
      Swal.fire({
        title: "Etes-vous sûr?",
        text: "Ce Projet sera supprimé !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000f51",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Envoyer une requête de suppression au serveur
          axios.delete(`${Constants.BASE_URL}/delete-folder/${folder.id}`);

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Projet supprimé avec succès",
            showConfirmButton: false,
            toast: true,
            timer: 1500,
          });
          // Mettre à jour l'état local pour refléter la suppression
          setFolders((prevFolders) =>
            prevFolders.filter((f) => f.id !== folder.id)
          );

          // Masquer le dropdown après la suppression
          hideDropdown();
        }
      });
    } catch (error) {
      console.error("Erreur lors de la suppression du dossier", error);
    }
  };

  const switchToListView = () => {
    setViewMode("list");
  };

  const hideDropdown = () => {
    setIsDropdownVisible(false);
  };

  // Fonction pour passer à la page précédente
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Fonction pour passer à la page suivante
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filteredFolders.length / itemsPerPage))
    );
  };





  const refreshFolders = () => {
    setIsLoading(true);
    axios
      .get(`${Constants.BASE_URL}/list-root-folders-for-user-role`)
      .then((response) => {
        setIsLoading(false);
        const rootFolders = response.data.folders.filter(
          (folder) => !folder.parent_id
        );
        setFolders(rootFolders);    
        //console.log(rootFolders);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des dossiers", error);
      });
  };
  
  
  /*
  const refreshFolders = async () => {
    try {

      const response = await axios.get(
        `${Constants.BASE_URL}/list-root-folders-for-user-role`
      );
      const rootFolders = response.data.folders.filter(
        (folder) => !folder.parent_id
      );
      setFolders(rootFolders);
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers", error);
    }
  };
*/
  const handleContextMenu = (e, folder) => {
    // Peut contenir des actions spécifiques au clic droit
    e.preventDefault();
    setContextMenuFolder(folder);
    setIsDropdownVisible(true);
    setProjectName(folder.name);
    //console.log(projectName)

    // Appeler fetchFolderTasks seulement si l'option "Lister Tâches du Dossier" est sélectionnée
    if (e.detail === 1) {
      fetchFolderTasks(folder.id);
    }
  };

  /*
  useEffect(() => {
    const fetchFolders = async () => {
      try {
        //const response = await axios.get(`${Constants.BASE_URL}/list-folders`);
        const response = await axios.get(
          `${Constants.BASE_URL}/list-root-folders-for-user-role`
        );
        const rootFolders = response.data.folders.filter(
          (folder) => !folder.parent_id
        );        
        setFolders(rootFolders);
        //console.log(rootFolders);
      } catch (error) {
        console.error("Erreur lors de la récupération des dossiers", error);
      }
    };
    fetchFolders();
  }, []);
*/
useEffect(() => {
  const fetchFolders = () => {
    setIsLoading(true);
    axios
      .get(`${Constants.BASE_URL}/list-root-folders-for-user-role`)
      .then((response) => {
        setIsLoading(false);
        const rootFolders = response.data.folders.filter(
          (folder) => !folder.parent_id
        );
        setFolders(rootFolders);
        //console.log(rootFolders);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des dossiers", error);
      });
  };

  fetchFolders();
}, []);

  const handleFolderClick = async (folder) => {
    try {
      // Charger le contenu du dossier
      const response = await axios.get(
        `${Constants.BASE_URL}/folder-content/${folder.id}`
      );
      setSelectedFolder(folder);
      setFolderContent(response.data.content);
      setCurrentFolderId(folder.id);
      fetchUserPermissions();
      navigate(`/documents/details/${folder.id}/${folder.name}`);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du contenu du dossier",
        error
      );
      //console.log(folder);
    }
  };

  // Filtrer les dossiers
  const filteredFolders = folders.filter(
    (folder) =>
      folder.name &&
      folder.name.toLowerCase().includes(filterName.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFolders = filteredFolders.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Changement de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        hideDropdown();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      const dropdown = document.getElementById("dropId"); // Remplacez 'votreDropdownId' par l'ID réel de votre dropdown
      const isClickInsideDropdown = dropdown && dropdown.contains(e.target);

      if (!isClickInsideDropdown) {
        hideDropdown();
      }
    };

    document.addEventListener("click", handleDocumentClick);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // Contrôles de changement de vue
  const renderViewModeControls = () => {
    return (
      <div>
        <div
          id="btnContainer"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ order: 1 }}>
            <button
              className={`btn ${viewMode === "grid" ? "active" : ""}`}
              onClick={switchToGridView}
            >
              <i className="fa fa-th-large"></i> Grille
            </button>
            <button
              className={`btn ${viewMode === "list" ? "active" : ""}`}
              onClick={switchToListView}
            >
              <i className="fa fa-bars"></i> Liste
            </button>
          </div>
          <div
            style={{
              order: 2,
              marginLeft: "auto",
              width: "20%",
              marginTop: -20,
            }}
          >
            <label htmlFor="filterName" className="form-label">
              Filtrer par nom :
            </label>
            <input
              type="text"
              id="filterName"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              className="form-control"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleDropdownSelect = (eventKey) => {
    if (eventKey === "option1") {
      // Appeler la fonction openModal lorsque l'option "modifier" est sélectionnée
      openModal();
    } else if (eventKey === "option2") {
      // Appeler la fonction handleDeleteFolder avec le dossier actuel
      handleDeleteFolder(contextMenuFolder);
    } else if (eventKey === "option3") {
      // Si l'utilisateur a sélectionné "Ajouter des Tâches", ouvrir la modalité des tâches
      openTaskModal();
    }
    // Ajoutez d'autres logiques pour gérer les autres options du dropdown si nécessaire
  };

  // Vue paginée
  // Vue paginée
  
  const renderView = () => {

    {/*
      console.log(currentFolders);
      */
    }

    const columnStyle =
      viewMode === "grid" ? { width: "20%" } : { width: "20%" };

    if (viewMode === "grid") {
      return (
        <>
      
      {isLoading ? (<Loader />):(
        <>
        {userPermissions.some(
                (permission) => permission.name === "view_project"
              ) ? (
                  <>
                  {filteredFolders.length > 0 ? (
                    <>
                      <div className="row mt-4">
                        {/** <button onClick={()=>refreshFolders()}>Refresh</button> **/}
                        {currentFolders.map((folder) => (
                          <div
                            key={folder.id}
                            className="column"
                            style={columnStyle}
                            onDoubleClick={() => handleFolderClick(folder)}
                          >
                            <ContextMenuTrigger
                              id={`context-menu-${folder.id}`}
                              holdToDisplay={1000}
                            >
                              <div
                                onContextMenu={(e) => handleContextMenu(e, folder)}
                              >
                                {/**<i class="fas fa-ellipsis-v"></i>**/}

                                {(folder.subfolders &&
                                  folder.subfolders.length > 0) ||
                                (folder.files && folder.files.length > 0) ? (
                                  <img
                                    src={folderfill}
                                    style={{ width: "70px" }}
                                    className="img img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={folderempty}
                                    style={{ width: "70px" }}
                                    className="img img-fluid"
                                  />
                                )}
                                <br />
                                <ProgressBar
                                  now={folder.pstate}
                                  label={`${folder.pstate}%`}
                                  className="mt-2 mb-2"
                                />
                                <>
                                  {contextMenuFolder &&
                                  contextMenuFolder.id === folder.id ? (
                                    <div
                                      style={{ fontSize: 13, fontWeight: "bolder" }}
                                    >
                                      <Dropdown
                                        onSelect={handleDropdownSelect}
                                        show={
                                          userPermissions.some(
                                            (permission) =>
                                              permission.name ===
                                              "view_contextual_menu"
                                          ) && isDropdownVisible
                                        }
                                        onHide={hideDropdown}
                                        id="dropId"
                                      >
                                        {folder.name}
                                        <Dropdown.Menu style={{ marginTop: -60 }}>
                                          {userPermissions.some(
                                            (permission) =>
                                              permission.name === "edit_project"
                                          ) && (
                                            <Dropdown.Item eventKey="option1">
                                              modifier ce projet
                                            </Dropdown.Item>
                                          )}

                                          {userPermissions.some(
                                            (permission) =>
                                              permission.name === "delete_project"
                                          ) && (
                                            <Dropdown.Item
                                              eventKey="option2"
                                              onClick={() =>
                                                handleDeleteFolder(
                                                  contextMenuFolder
                                                )
                                              }
                                            >
                                              supprimer cet projet
                                            </Dropdown.Item>
                                          )}
                                          {userPermissions.some(
                                            (permission) =>
                                              permission.name ===
                                              "manage_project_task"
                                          ) && (
                                            <Dropdown.Item
                                              eventKey="option3"
                                              onClick={async () => {
                                                try {
                                                  const response = await axios.get(
                                                    `${Constants.BASE_URL}/folders/${contextMenuFolder.id}/tasks`
                                                  );
                                                  setFolderTasks(
                                                    response.data.tasks
                                                  );
                                                  setFolderContent(
                                                    response.data.tasks
                                                  );
                                                  setIsTaskModalOpen(true);
                                                } catch (error) {
                                                  console.error(
                                                    "Erreur lors de la récupération des tâches du dossier",
                                                    error
                                                  );
                                                }
                                              }}
                                            >
                                              Tâches du projet
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  ) : (
                                    <div
                                      style={{ fontSize: 13, fontWeight: "bolder" }}
                                    >
                                      {folder.name}
                                    </div>
                                  )}
                                </>

                                <br />
                                <br />
                              </div>
                            </ContextMenuTrigger>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p
                      style={{
                        fontWeight: "bolder",
                        fontSize: 18,
                        marginBottom: 20,
                      }}
                    >
                      Aucun projet disponible pour l'instant.
                    </p>
                  )}
                  </>
              ) : (                
                  <>
                    <div className="alert alert-danger mt-3" role="alert">
                      Vous n'avez pas le droit d'accéder à ce contenu.
                    </div>
                  </>

              )}
        </>
                )}


        </>
      );
    } else {
      return (
        <>

          {userPermissions.some(
            (permission) => permission.name === "view_project"
          ) ? (
            <>
              {filteredFolders.length > 0 ? (
                <>
                  <div className="ro mt-4" style={{width:'30%'}}>
                    {/** <button onClick={()=>refreshFolders()}>Refresh</button> **/}
                    {currentFolders.map((folder) => (
                      <div
                        key={folder.id}
                        className=""
                        style={{width:'100%'}}
                        onDoubleClick={() => handleFolderClick(folder)}
                      >
                        <ContextMenuTrigger
                          id={`context-menu-${folder.id}`}
                          holdToDisplay={1000}
                        >
                          <div
                            onContextMenu={(e) => handleContextMenu(e, folder)}
                          >
                            {/**<i class="fas fa-ellipsis-v"></i>**/}

                            {(folder.subfolders &&
                              folder.subfolders.length > 0) ||
                            (folder.files && folder.files.length > 0) ? (
                              <img
                                src={folderfill}
                                style={{ width: "70px" }}
                                className="img img-fluid"
                              />
                            ) : (
                              <img
                                src={folderempty}
                                style={{ width: "70px" }}
                                className="img img-fluid"
                              />
                            )}
                            <br />
                            <ProgressBar
                              now={folder.pstate}
                              label={`${folder.pstate}%`}
                              className="mt-2 mb-2"
                            />
                            <>
                              {contextMenuFolder &&
                              contextMenuFolder.id === folder.id ? (
                                <div
                                  style={{ fontSize: 13, fontWeight: "bolder" }}
                                >
                                  <Dropdown
                                    onSelect={handleDropdownSelect}
                                    show={
                                      userPermissions.some(
                                        (permission) =>
                                          permission.name ===
                                          "view_contextual_menu"
                                      ) && isDropdownVisible
                                    }
                                    onHide={hideDropdown}
                                    id="dropId"
                                  >
                                    {folder.name}
                                    <Dropdown.Menu style={{ marginTop: -60 }}>
                                      {userPermissions.some(
                                        (permission) =>
                                          permission.name === "edit_project"
                                      ) && (
                                        <Dropdown.Item eventKey="option1">
                                          modifier ce projet
                                        </Dropdown.Item>
                                      )}

                                      {userPermissions.some(
                                        (permission) =>
                                          permission.name === "delete_project"
                                      ) && (
                                        <Dropdown.Item
                                          eventKey="option2"
                                          onClick={() =>
                                            handleDeleteFolder(
                                              contextMenuFolder
                                            )
                                          }
                                        >
                                          supprimer cet projet
                                        </Dropdown.Item>
                                      )}
                                      {userPermissions.some(
                                        (permission) =>
                                          permission.name ===
                                          "manage_project_task"
                                      ) && (
                                        <Dropdown.Item
                                          eventKey="option3"
                                          onClick={async () => {
                                            try {
                                              const response = await axios.get(
                                                `${Constants.BASE_URL}/folders/${contextMenuFolder.id}/tasks`
                                              );
                                              setFolderTasks(
                                                response.data.tasks
                                              );
                                              setFolderContent(
                                                response.data.tasks
                                              );
                                              setIsTaskModalOpen(true);
                                            } catch (error) {
                                              console.error(
                                                "Erreur lors de la récupération des tâches du dossier",
                                                error
                                              );
                                            }
                                          }}
                                        >
                                          Tâches du projet
                                        </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                <div
                                  style={{ fontSize: 13, fontWeight: "bolder" }}
                                >
                                  {folder.name}
                                </div>
                              )}
                            </>

                            <br />
                            <br />
                          </div>
                        </ContextMenuTrigger>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: 18,
                    marginBottom: 20,
                  }}
                >
                  Aucun projet disponible pour l'instant.
                </p>
              )}
            </>
          ) : (
            <>
              {!userPermissions.some(
                (permission) => permission.name === "view_project"
              ) && (
                <>
                  <div
                    className="alert alert-danger mt-3"
                    role="alert"
                    style={{ display: "noe" }}
                  >
                    Vous n'avez pas le droit d'accéder à ce contenu.
                  </div>
                </>
              )}

              {/* Contenu de votre Dropdown ici */}
            </>
          )}
        </>
      );

    }
  };

  return (
    <div style={{ marginBottom: 30 }}>
      {/**<FolderForm currentFolderId={currentFolderId} />* */}
      <FolderForm
        currentFolderId={currentFolderId}
        onFolderCreate={refreshFolders}
      />

      {/* Fenêtre modale pour la modification du nom du dossier */}
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        style={{ marginTop: 0, backdropFilter: "blur(8px)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:19}}>Modifier le nom du Projet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="newFolderName" className="form-label">
            Nouveau nom du projet :
          </label>
          <input
            type="text"
            id="newFolderName"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            className="form-control"
            style={{fontSize:16}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn31"
            variant="secondar"
            onClick={() => setIsModalOpen(false)}
            style={{ fontWeight: "bolder", fontSize: 15 }}
          >
            Annuler
          </Button>
          <Button
            className="btn3"
            variant="primar"
            onClick={updateFolderName}
            style={{ fontWeight: "bolder", fontSize: 15 }}
          >
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isTaskModalOpen}
        onHide={closeTaskModal}
        size="lg"
        style={{ marginTop: 0, backdropFilter: "blur(8px)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:19, fontWeight:'bolder'}}>{`Tâches du Projet: ${projectName}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/*
            <div className="col-md-6">
              <p style={{ fontWeight: "bolder" }}>
                Liste complète des Tâches liées au Projet
              </p>
            </div>  
   */}

            <div className="col-md-12 float-end">
              {userPermissions.some(
                (permission) => permission.name === "add_task"
              ) && (
                <Button
                  variant="primary"
                  onClick={handleShoww}
                  className="mb-3 float-end btn3"
                  style={{ fontWeight: "bolder", fontSize:16}}
                >
                  Ajouter une Tâche
                </Button>
              )}
            </div>
          </div>

          {/* Formulaire pour ajouter une tâche */}

          {/*


 */}

          <Modal
            show={show}
            onHide={handleClose}
            style={{ marginTop: 0, backdropFilter: "blur(8px)" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{fontSize:19}}>{"Modifier Tâche"}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#fff" }}>
              <label htmlFor="updatedTaskName" className="form-label">
                Nouveau nom de la tâche :
              </label>
              <input
                type="text"
                id="updatedTaskName"
                value={editedTask?.name}
                style={{fontSize:16}}
                className="form-control"
                onChange={(e) =>
                  setEditedTask((prevEditedTask) => ({
                    ...prevEditedTask,
                    name: e.target.value,
                  }))
                }
              />

              <label htmlFor="updatedTaskStatus" className="form-label">
                Nouveau statut de la tâche :
              </label>
              <select
                id="updatedTaskStatus"
                style={{fontSize:16}}
                value={editedTask?.status || ""}
                onChange={(e) =>
                  setEditedTask((prev) => ({ ...prev, status: e.target.value }))
                }
                className="form-select"
              >
                <option value="en_cours">En cours</option>
                <option value="termine">Terminé</option>
              </select>

              <div className="float-end mt-3">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  style={{ fontWeight: "bolder", background:'#000f51 !important;' }}
                >
                  Fermer
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  onClick={handleTaskUpdate}
                  className="btn3"
                  style={{ fontWeight: "bolder" }}
                >
                  Enregistrer
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showw}
            onHide={handleClosee}
            style={{ marginTop: 0, backdropFilter: "blur(8px)" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{fontSize:19}}>{"Ajouter Une Tâche"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label htmlFor="taskName" className="form-label">
                Nom de la Tâche :
              </label>
              <input
                type="text"
                id="taskName"
                value={taskName}
                style={{fontSize:16}}
                onChange={(e) => setTaskName(e.target.value)}
                className="form-control"
              />
              <label htmlFor="taskStatus" className="form-label">
                Statut de la Tâche :
              </label>
              <select
                id="taskStatus"
                value={taskStatus}
                style={{fontSize:16}}
                onChange={(e) => setTaskStatus(e.target.value)}
                className="form-select"
              >
                <option value="en_cours">En cours</option>
                <option value="termine">Terminé</option>
              </select>
              <Button
                variant="primary"
                onClick={handleAddTask}
                className="float-end mt-2 mb-4 btn3"
                style={{ fontWeight: "bolder" }}
              >
                Ajouter la Tâche
              </Button>
            </Modal.Body>
          </Modal>

          {/*folderTasks*/}
          {/*si folderContent.length non null utiliser ça sinon si c est null ou inferieur a 0 utiliser folderTasks.length*/}

          {userPermissions.some(
            (permission) => permission.name === "view_task"
          ) ? (
            <>
              {folderContent.length > 0 ? (
                <>
                  <BootstrapTable
                    keyField="id"
                    data={folderContent}
                    classes="custom-table" // Ajoutez une classe personnalisée à la table
                    columns={[
                      { dataField: "id", text: "ID" },
                      {
                        dataField: "name",
                        text: "Tâche ",
                        /*
                        filter: textFilter({
                          placeholder: "saisir une tâche",
                        }),
                         */
                      },
                      {
                        dataField: "status",
                        text: "Statut",
                        formatter: (cell, row) =>
                          row.status === "termine" ? (
                            <span className="badge bg-success2 rounded-pill">
                              <i className="fas fa-check"></i> {row.status}
                            </span>
                          ) : (
                            <span
                              className="badge bg-warning2 rounded-pill"
                              style={{ color: "black" }}
                            >
                              {row.status}
                            </span>
                          ),
                      },
                      {
                        dataField: "Action",
                        text: "Action",
                        formatter: (cell, row) => (
                          <>
                            {userPermissions.some(
                              (permission) => permission.name === "edit_task"
                            ) && (
                              <Button
                                variant="primary"
                                className="btn btn-warning"
                                onClick={() => handleShow(row.id)}
                                style={{
                                  background: "#44abb6",
                                  color: "white",
                                  borderColor: "#44abb6",
                                }}
                              >
                                <i className="fa fa-edit"></i>
                              </Button>
                            )}
                            &nbsp;
                            {userPermissions.some(
                              (permission) => permission.name === "delete_task"
                            ) && (
                              <button
                                onClick={() => handleDeleteTask(row.id)}
                                className="btn btn-danger"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            )}
                          </>
                        ),
                      },
                    ]}
                    pagination={paginationFactory()}
                    striped // Activer les lignes rayées
                    filter={filterFactory()}
                  />
                </>
              ) : (
                // Affichez un message par défaut si les deux longueurs sont null ou inférieures à 0
                <p>Aucune donnée disponible</p>
              )}
            </>
          ) : (
            // Bloc à afficher lorsque la condition n'est pas vérifiée
            <>
              {!userPermissions.some(
                (permission) => permission.name === "view_task"
              ) && (
                <>
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ display: "" }}
                  >
                    Vous n'avez pas le droit d'accéder à ce contenu.

                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
        {/**  <Modal.Footer>

  </Modal.Footer> */}
      </Modal>

      {/* Fenêtre modale pour la modification de la tâche */}

      {/*<h2 style={{ fontSize: 20 }}>Liste des Dossiers à la Racine</h2> */}

      {/* Ajouter les contrôles pour changer de vue (grille/liste) */}
      {renderViewModeControls()}

      {/* Afficher la vue paginée */}
      {renderView()}
      {/* <TaskUpdateModal /> */}
      {/* Pagination */}
      {/* Pagination */}

      <>
        {/*console.log(itemsPerPage)*/}
        {filteredFolders.length > 20 ? (
          <>
            <nav>
              <ul className="pagination mt-0">
                {/* Bouton "Précédent" */}
                <li className="page-item">
                  <button onClick={handlePrevPage} className="page-link">
                    Précédent
                  </button>
                </li>

                {/* Pages numérotées */}
                {Array.from(
                  { length: Math.ceil(filteredFolders.length / itemsPerPage) },
                  (_, index) => (
                    <li key={index + 1} className="page-item">
                      <button
                        onClick={() => paginate(index + 1)}
                        className="page-link"
                      >
                        {index + 1}
                      </button>
                    </li>
                  )
                )}

                {/* Bouton "Suivant" */}
                <li className="page-item">
                  <button onClick={handleNextPage} className="page-link">
                    Suivant
                  </button>
                </li>
              </ul>
            </nav>
          </>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default FolderList;
