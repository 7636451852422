import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Constants from '../Constants';
import { useNavigate, useParams } from 'react-router-dom';
import FolderForm from './FolderForm';
import folderfill from '../assets/img/folder-fill.png';
import folderempty from '../assets/img/folder-empty.png';
import './styles.css'; // Assurez-vous d'ajuster le chemin selon votre structure de fichiers
import Swal from 'sweetalert2';
import { getUserPermissions } from "../services/authService";

const FolderDetails = () => {
    const [selectedFolder, setSelectedFolder] =  useState({ subfolders: [], files: [] });
    const [folderContent, setFolderContent] = useState({ subfolders: [], files: [] });
    const [currentPath, setCurrentPath] = useState(''); // Nouvelle state pour stocker le chemin actuel
    const [folders, setFolders] = useState([]);
    const navigate = useNavigate();
    const { id, name } = useParams();
    const [userPermissions, setUserPermissions] = useState([]);


    const handlePathChange = (newPath) => {
        // Mettez à jour la state du chemin actuel
        setCurrentPath(newPath);
        // Faire quelque chose avec le nouveau chemin, par exemple, l'afficher dans la console
        console.log('Nouveau chemin créé:', newPath);
    };

    const fetchUserPermissions = async () => {
      try {
        const permissions = await getUserPermissions();
        setUserPermissions(permissions);
        console.log(userPermissions)
      } catch (error) {
        console.error("Error fetching user permissions:", error);
      }
    };
  
    useEffect(() => {
      fetchUserPermissions();
      console.log(userPermissions);
    }, []);
  
    
    const handleDeleteFolder = async (folder) => {
      // Logique pour la suppression du dossier, par exemple, afficher une confirmation
      console.log(`Suppression du dossier ${folder.name}`);
  
      try {
        Swal.fire({
          title: "Etes-vous sûr?",
          text: "Ce Dossier sera supprimé !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprimer!",
        }).then((result) => {
          if (result.isConfirmed) {
            // Envoyer une requête de suppression au serveur
            axios.delete(`${Constants.BASE_URL}/delete-folder/${folder.id}`);
  
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Projet supprimé avec succès",
              showConfirmButton: false,
              toast: true,
              timer: 1500,
            });
            // Mettre à jour l'état local pour refléter la suppression
            setFolders((prevFolders) =>
              prevFolders.filter((f) => f.id !== folder.id)
            );
  
            // Masquer le dropdown après la suppression
            //hideDropdown();

                    // Rafraîchir le contenu du dossier après la suppression
        refreshFoldersD();

          }
        });
      } catch (error) {
        console.error("Erreur lors de la suppression du dossier", error);
      }
    };


    const handleDeleteFile = async (fileId, fileName) => {
      console.log('ID=',fileId.id);
      try {
        Swal.fire({
          title: "Etes-vous sûr?",
          text: "Ce fichier sera supprimé !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprimer!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Envoyer une requête de suppression au serveur
            await axios.delete(`${Constants.BASE_URL}/delete-file/${fileId.id}`);
    
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Fichier supprimé avec succès",
              showConfirmButton: false,
              toast: true,
              timer: 1500,
            });
    
            // Rafraîchir le contenu du dossier après la suppression
            refreshFoldersD();
          }
        });
      } catch (error) {
        console.error("Erreur lors de la suppression du fichier", error);
      }
    };
    

    const refreshFoldersD = async () => {
      try {
        const folderResponse = await axios.get(`${Constants.BASE_URL}/folder-details/${id}/${name}`);
        console.log('Folder Response:', folderResponse.data);
        setSelectedFolder(folderResponse.data.folder);

        const contentResponse = await axios.get(`${Constants.BASE_URL}/folder-content/${id}`);
        console.log('Content Response:', contentResponse.data);
        //console.log(`${Constants.BASE_URL}/folder-details/${id}/${name}`);
        setFolderContent(contentResponse.data);
        //const [folderContent, setFolderContent] = useState({ subfolders: [], files: [] });
      } catch (error) {
        console.error('Erreur lors de la récupération des dossiers', error);
      }
    };

    const handleAddFile = async (newFile) => {
      try {
        // Fetch the content first
        const contentResponse = await axios.get(`${Constants.BASE_URL}/folder-content/${id}`);
        console.log('Content Response:', contentResponse.data);
    
        // Fetch the folder details and update the selected folder
        const folderResponse = await axios.get(`${Constants.BASE_URL}/folder-details/${id}/${name}`);
        console.log('Folder Response:', folderResponse.data);
        setSelectedFolder(folderResponse.data.folder);
    
        console.log('Fichier ajouté:', newFile);
    
        // Update the state with the new file
        setFolderContent((prevState) => {
          const updatedFiles = [...prevState.files, newFile];
          console.log('Updated Files:', updatedFiles); // Log the updated state
          return {
            ...prevState,
            files: updatedFiles,
          };
        });
      } catch (error) {
        console.error('Erreur lors de l\'ajout de fichiers', error);
      }
    };
    
    

    useEffect(() => {
        const fetchFolderDetails = async () => {
            try {
                const folderResponse = await axios.get(`${Constants.BASE_URL}/folder-details/${id}/${name}`);
                console.log('Folder Response:', folderResponse.data);
                setSelectedFolder(folderResponse.data.folder);
    
                const contentResponse = await axios.get(`${Constants.BASE_URL}/folder-content/${id}`);
                console.log('Content Response:', contentResponse.data);
                //console.log(`${Constants.BASE_URL}/folder-details/${id}/${name}`);
                setFolderContent(contentResponse.data);
                //console.log('Content Response:', contentResponse.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des détails du dossier', error);
            }
        };
    
        fetchFolderDetails();
    }, [id, name]);
    
 // Fonction pour télécharger un fichier
 const handleDownloadFile = async (fileId, fileName) => {
    try {
      // Effectuez une requête pour obtenir le fichier en tant que blob
      const response = await axios.get(`${Constants.BASE_URL}/download-file/${fileId}`, {
        responseType: 'blob',
      });

      // Créez un lien temporaire pour télécharger le fichier blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Nettoyez après le téléchargement
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier', error);
    }
  };    

    const handleBackButtonClick = () => {
        //navigate('/documents');
        // Utiliser goBack pour retourner à l'URL précédente
        navigate(-1);
    };


    
    const handleSubfolderClick = async (subfolderId, subfolderName) => {
        try {
            // Attendez quelques millisecondes avant d'effectuer la prochaine requête
            await new Promise(resolve => setTimeout(resolve, 100));
    
            // Utilisez à la fois l'ID et le nom pour récupérer les détails du sous-dossier
            const folderResponse = await axios.get(`${Constants.BASE_URL}/folder-details/${subfolderId}/${subfolderName}`);
            setSelectedFolder(folderResponse.data.folder);
            //console.log(folderResponse.data.folder);
            //console.log('OK');
            const contentResponse = await axios.get(`${Constants.BASE_URL}/folder-content/${subfolderId}`);
            setFolderContent(contentResponse.data);
            //console.log(contentResponse.data.content);
            //console.log('subfolderId---');
            //console.log(subfolderId);
            //console.log('subfolderName---');
            //console.log(subfolderName);
            // Naviguer vers le composant FolderDetails avec les nouveaux paramètres d'ID et de nom du sous-dossier
            navigate(`/documents/details/${subfolderId}/${subfolderName}`);
        } catch (error) {
            console.log('NON OK');
            console.error('Erreur lors de la récupération du contenu du dossier', error);
            // Ajoutez ici la gestion de l'erreur côté client
            if (error.response && error.response.status === 429) {
                // Gérer spécifiquement l'erreur 429 (Too Many Requests)
                console.log('Le serveur est surchargé. Veuillez réessayer plus tard.');
            } else {
                // Gérer d'autres erreurs de manière générale
                console.log('Une erreur s\'est produite. Veuillez réessayer.');
            }
        }
    };
    
    /*
    const handleSubfolderClick = async (subfolderId, subfolderName) => {
        try {
            // Utilisez à la fois l'ID et le nom pour récupérer les détails du sous-dossier
            const folderResponse = await axios.get(`${Constants.BASE_URL}/folder-details/${subfolderId}/${subfolderName}`);
            setSelectedFolder(folderResponse.data.folder);
    
            const contentResponse = await axios.get(`${Constants.BASE_URL}/folder-content/${subfolderId}`);
            setFolderContent(contentResponse.data.content);
    
            // Naviguer vers le composant FolderDetails avec les nouveaux paramètres d'ID et de nom du sous-dossier
            navigate(`/documents/details/${subfolderId}/${subfolderName}`);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du sous-dossier', error);
        }
    };
    */
    const renderFiles = (files) => (
        <ul>
            {files.map((file) => (
                <li
                    style={{ fontWeight: 'normal', cursor: 'pointer', listStyle: 'none' }}
                    key={file.id}
                    className='survol'
                >
                    <i className='fa fa-file' style={{ color: '#007bff', fontSize: 25 }}></i> {file.name}
                </li>
            ))}
        </ul>
    );
    
    const renderFolderContent = (content) => (
        <ul>
          {content.subfolders.map((item) => (
            <li
              style={{ fontWeight: 'bold', cursor: 'pointer', listStyle: 'none', marginBottom: 10}}
              key={item.id}              
            >
                <>
                {/**<img src={folderempty} style={{width:'30px', marginBottom:10}}/> */}
                {/*<i className='fa fa-folder' style={{color:'#ffc107', fontSize:25}}></i> */}
                

               <span className='survol' onDoubleClick={() => handleSubfolderClick(item.id, item.name)}>                
               <img  src={folderempty} style={{ width: '25px' }} 
                />
                {item.name} </span>
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


               {userPermissions.some(
                                            (permission) =>
                                              permission.name === "delete_project"
                                          ) && (
                                            
                                            <span onClick={()=>{
                console.log(`${item.name}`);
                //handleDeleteFolder(`${item}`);
                handleDeleteFolder(item);
                //handleSubfolderClick(item.id, item.name);
                }}
                style={{color:'#b70707', fontSize:14, zIndex:1, cursor:'pointer'}}
                >
                  <i className='fa fa-trash-alt'></i>
               </span>

                                            
                                          )}

               

                </>
            </li>
          ))}
          {content.files.length > 0 && (
            <div>
              {content.files.map((file) => (
                <div key={file.id} style={{marginBottom:18}} 
                                    

                >
                  
                    <td>
                      <a href={`${Constants.BASE_URL}/download-file/${file.id}`} download={file.name} className='survol'>
                        <i className='fa fa-file' style={{ color: 'rgb(87 154 200)', fontSize: 25, marginRight: 5 }}></i>                      
                        <span style={{color:'#4f4f4f', fontWeight:'500'}}>{file.name}</span>
                      </a>
                    </td>
                    <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                    {userPermissions.some(
                                            (permission) =>
                                              permission.name === "delete_file"
                                          ) && (
                                            <span onClick={()=>{
                console.log(`${file.name}`);
                //handleDeleteFolder(`${item}`);
                handleDeleteFile(file);
                //handleSubfolderClick(item.id, item.name);
                }}
                style={{color:'#b70707', fontSize:14, zIndex:1, cursor:'pointer'}}
                >
                  <i className='fa fa-trash-alt'></i>
               </span>
                                          )}





                    </td>
                  
                </div>
              ))}
            </div>
          )}
        </ul>
      );
      
    
    
    return (
        <div style={{marginBottom:300}}>
            <FolderForm onPathChange={handlePathChange} onFolderCreate={refreshFoldersD} onFileAdd={handleAddFile} onRefreshFoldersD={refreshFoldersD}/>
            {/* <hr style={{borderWidth:2, marginTop:-30}}/> */}
            {/*console.log('voila:',folderContent)*/}
            {selectedFolder && (
            <>
                <div className='' style={{background:''}}>
                    <button className='btn btn-seconday' style={{marginBottom:0, fontSize:20, fontWeight:'bolder'}} onClick={handleBackButtonClick}>..{currentPath || 'Racine'}</button>
                </div>
                <br/>
            </>
            )}
            {folderContent.subfolders.length > 0 || folderContent.files.length > 0 ? (
                <div>
                    {renderFolderContent(folderContent)}
                </div>
            ) : (
                <p>Aucun contenu trouvé dans ce dossier.</p>
            )}
        </div>
    );
};

export default FolderDetails;
