import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../partials/BreadCrumb';
import CardHeader from '../../partials/miniComponent/CardHeader/CardHeader';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Constants from '../../../Constants';
import Swal from 'sweetalert2';
import NoDataFound from '../../partials/miniComponent/NoDataFound';
import Loader from '../../partials/miniComponent/Loader';
import Pagination from 'react-js-pagination';
import { getUserPermissions } from '../../../services/authService';

const Visibility = (props) => {
    const [input, setInput] = useState({
        order_by: 'created_at',
        per_page: 10,
        direction: 'desc',
        search: '',
    });
    const [userPermissions, setUserPermissions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    //const [input, setInput] = useState({});
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //const [attributes, setAttributes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [folders, setFolders] = useState([]);
    const [selectedFolderIds, setSelectedFolderIds] = useState([]);

    const navigate = useNavigate();

    const [itemsCountPerPage, setItemsCounterPerPage] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [startFrom, setStartFrom] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [modalTitle, setModalTitle] = useState('Add');
    const [valueModalTitle, setValueModalTitle] = useState('Add');
    const [valueModalShow, setValueModalShow] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    
    const [modalValue, setModalValue] = useState([]);
    const [ValueToPass, setValueToPass] = useState();
    const [modalValueShow, setModalValueShow] = useState(false);
    const [rolesData, setRolesData] = useState([]); // Ajoutez cet état pour suivre les données des rôles
    const [foldersData, setFoldersData] = useState([]); // Ajoutez cet état pour suivre les données des dossiers


    const updateFolderListAfterDelete = (attributeId) => {
        // Filtrer les catégories pour exclure celle supprimée
        const updatedAttributes = attributes.filter(attribute => attribute.id !== attributeId);
        setAttributes(updatedAttributes);
      };

    const handleValueDetailsModal = (value) =>{
        //console.log(value);
        setModalValue(value);
        setModalValueShow(true);
    }

    const fetchUserPermissions = async () => {
        try {
          const permissions = await getUserPermissions();
          setUserPermissions(permissions);
          //console.log(userPermissions)
        } catch (error) {
          console.error('Error fetching user permissions:', error);
        }
      };
  

    useEffect(() => {
      fetchUserPermissions();
    }, []);

    const handleFolderCreate = () =>{
        console.log(input);
        setIsLoading(true);

         // Créez un objet contenant les données à envoyer au serveur, y compris les folders
        const roleData = {
            name: input.name,
            folders: selectedFolders
        };

        axios.post(`${Constants.BASE_URL}/roles`, roleData)
        .then(res => {
            setIsLoading(false);
            console.log(res.data);

            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });

            setErrors([]);
            setInput({ name: '' });
            setModalShow(false);
            getAttributes();
            fetchUserPermissions();
        })
        .catch(errors => {
            setIsLoading(false);
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors);
            }
        });
    }


    
    const getAttributes = (pageNumber = 1) => {
        setIsLoading(true);
        //axios.get(`${Constants.BASE_URL}/users?page=${pageNumber}&search=${input.search}&order_by=${input.order_by}&per_page=${input.per_page}&direction=${input.direction}`).then(res => {
            //axios.get(`${Constants.BASE_URL}/rolesfolders?page=${pageNumber}&search=${input.search || ''}`)
            //&order_by=${input.order_by || ''}&direction=${input.direction || ''}
        axios.get(`${Constants.BASE_URL}/rolesfolders?page=${pageNumber}&per_page=${input.per_page || ''}&search=${input.search || ''}`).then(
            res => {
                setIsLoading(false);
                //console.log(res.data.data);
                setAttributes(res.data.data);
                setRolesData(res.data.data); // Mettez à jour les données des rôles
                setItemsCounterPerPage(res.data.meta.per_page);
                setStartFrom(res.data.meta.from);
                setTotalItemsCount(res.data.meta.total);
                setActivePage(res.data.meta.current_page);
            }
        ).catch(error => {
            setIsLoading(false);
            console.error("Error fetching roles:", error);
        });
    };

        // Nouvelle fonction pour charger les folders
        const fetchFolders = async () => {
            try {
                const response = await axios.get(`${Constants.BASE_URL}/list-projects-folders`);
                setFolders(response.data.folders);
                setFoldersData(response.data.folders); // Mettez à jour les données des dossiers
                //console.log('folders')
                //console.log(folders)
            } catch (error) {
                console.error("Erreur lors de la récupération des dossiers :", error);
            }
        };

        const handleInput = (e) => { setInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));};
    
        const getFolders = async () => {
            try {
                const response = await axios.get(`${Constants.BASE_URL}/list-folders`);
                return response.data.folders;
            } catch (error) {
                console.error("Error fetching folders:", error);
                return [];
            }
        };
                

        const handleUpdateFolders = (roleId, folderIds) => {
        };
        

        const handleFolderUpdate = (roleID, folderIDs) => {
            console.log(folderIDs);
            
            setIsLoading(true);        
    
            axios.put(`${Constants.BASE_URL}/roles/${roleID}/update-folders`, { folder_ids: folderIDs })
                .then(res => {
                    setIsLoading(false);
                    console.log(res);
                    //setAttributes(res.data)
                    setInput((prevInput) => ({
                        ...prevInput,
                        search: ' ',
                      }));

                    getAttributes(1);
                    fetchUserPermissions();
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.message,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1500
                    });
                    setModalShow(false);

                    //console.log(res.data.message);
                    // Ajoutez ici le traitement nécessaire après la mise à jour des dossiers
                    // Vous pouvez également utiliser les données mises à jour dans la réponse (res.data) si nécessaire
                })
                .catch(error => {
                    setIsLoading(false);
                    console.error("Error updating folders:", error);
                    // Ajoutez ici le traitement en cas d'erreur
                });
        };
        
        
        const handleValueEdit = (id) =>{
            console.log(input);
            setIsLoading(true);
            axios.put(`${Constants.BASE_URL}/roles/${input.id}`, input)
            .then(res => {
                setIsLoading(false);
                console.log(res.data);
                Swal.fire({
                    position: 'top-end',
                    icon: res.data.cls,
                    title: res.data.msg,
                    showConfirmButton: false,
                    toast: true,
                    timer: 1500
                });
        
                setErrors([]);
                setInput({ name: '' });
                setValueModalShow(false);
                //setModalShow(false);
                getAttributes();
                fetchUserPermissions();
            })
            .catch(errors => {
                setIsLoading(false);
                if (errors.response.status === 422) {
                    setErrors(errors.response.data.errors);
                }
            });
    
        }
    
    
        const handleRoleDelete = (id) => {
    
            Swal.fire({
                title: 'Etes-vous sûr?',
                text: "Ce Rôle sera supprimé !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer!'
              }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${Constants.BASE_URL}/roles/${id}`).then(res=>{
                        updateFolderListAfterDelete(id); // Mettre à jour la liste après la suppression
                        Swal.fire({
                            position: 'top-end',
                            icon: res.data.cls,
                            title: res.data.msg,
                            showConfirmButton: false,
                            toast: true,
                            timer: 1500
                        })
               
                    });
                }
              })
    
        }
        
    
    
        const handleModal = (attribute = undefined) => {
            if (attribute != undefined) {
                setModalTitle('Modifier');
                setInput({ name: attribute.name, id: attribute.id });
                setSelectedFolders(attribute.folders);
            } else {
                setModalTitle('Ajouter');
                setInput({ name: '' });
                setSelectedFolders([]);
            }
            setErrors([]);
            setModalShow(true);
        };
    
        const handleFolderCheckbox = (folderId) => {
            setSelectedFolders((prevFolders) => {
                if (prevFolders.some(folder => folder.id === folderId)) {
                    // Si le dossier est déjà sélectionné, le retirez
                    const updatedFolders = prevFolders.filter(folder => folder.id !== folderId);
                    //console.log('tableau: '+updatedFolders.map(folder => folder.id)); // Affiche le tableau d'IDs mis à jour
                    //setValueToPass(updatedFolders);
                    setValueToPass(updatedFolders.map(folder => folder.id));
                    return updatedFolders;
                } else {
                    // Sinon, ajoutez-le
                    const folderToAdd = folders.find(folder => folder.id === folderId);
                    console.log([...prevFolders, folderToAdd].map(folder => folder.id))
                    //console.log('tableau '+[...prevFolders, folderToAdd].map(folder => folder.id)); // Affiche le tableau d'IDs mis à jour
                    //setValueToPass([...prevFolders, folderToAdd]);
                    setValueToPass([...prevFolders, folderToAdd].map(folder => folder.id));
                    return [...prevFolders, folderToAdd];
                }
            });
        };
        



          const handleValueCreate = () =>{
        
            console.log(input);
            setIsLoading(true);
            axios.post(`${Constants.BASE_URL}/roles`, input)
            .then(res => {
                setIsLoading(false);
                console.log(res.data);
                Swal.fire({
                    position: 'top-end',
                    icon: res.data.cls,
                    title: res.data.msg,
                    showConfirmButton: false,
                    toast: true,
                    timer: 1500
                });
        
                setErrors([]);
                setInput({ name: '' }); // Réinitialiser le champ "name"
                setModalShow(false);
                getAttributes();
                fetchUserPermissions();
            })
            .catch(errors => {
                setIsLoading(false);
                if (errors.response.status === 422) {
                    setErrors(errors.response.data.errors);
                }
            });
        
    
        }
    
        useEffect(() => {
            // Chargez les folders lors du rendu initial
            fetchFolders();
        }, []);
    
        useEffect(() => {
            getAttributes();
        }, []);
    
        useEffect(() => {
            // Ce code sera exécuté à chaque mise à jour de attributes
            console.log(attributes);
            console.log('isEditMode');
            console.log(isEditMode);
        }, [attributes]);  // Utilisez attributes comme dépendance ici

        return (
            <>
              <BreadCrumb title={'Visibilité'}/>  
              {isLoading ? (<Loader />):
          
          <>
          {console.log('bloc 1 '+userPermissions.some(permission => permission.name === 'view_visibility'))}
          {userPermissions.some(permission => permission.name === 'view_visibility') ? (
        <>
          {/* Your content goes here */}
              <div className='row'>
                    <div className='col-md-12'>
                    <div className='card' style={{border:'none'}}>
                    <div className="card-header" style={{background: 'transparent', color:'', border:'none'}}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h4 className={'text-theme'}><i className='fa fa-folder' style={{fontSize:20}}></i> Visibilité - Accès aux Projets</h4>
                                    <Link to={props.link}>
{/*
                                    <button className={'btn btn3'} onClick={()=>handleModal()} style={{background:'#44abb6 !important',
                                        fontWeight:'bolder'}}>
                                        <i className={`fa-solid fa-plus`} />
                                        Ajouter &nbsp; {props.button_text}
                                    </button>
 */}
                                    </Link>
                                </div>
                            </div>

                            <div className="card-body" style={{background:'white)'}}>
                                <div className='ro'>
                                <div className='search-area mb-4' style={{margin:20}}>
                                    <div className='row'>
{
    /*
                                        <div className='col-md-2'>
                                            <br/>                                            
                                            <p className='' style={{fontWeight:'bolder', fontSize:18}}>Zone de recherche</p> 
                                        </div>    
     */
}
                                        <div className='col-md-3'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder'}}>mot clé</p>
                                                <input
                                                    className='form-control form-control-sm'
                                                    name={'search'}
                                                    type={'search'}
                                                    value={input.search}
                                                    onChange={handleInput}
                                                    placeholder={'saisir mot clé...'}
                                                    style={{height:'145px !important',fontSize:14}}
                                                />
                                            </label>
                                        </div>
                                        <div className='col-md-3'>
                                            <label className={'w-100'}>
                                                <p style={{color:'#000f51', fontWeight:'bolder'}}>Résultat par page</p>
                                                <select
                                                    className='form-select form-select-sm'
                                                    name={'per_page'}
                                                    value={input.per_page}
                                                    onChange={handleInput}
                                                    style={{fontSize:14}}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </label>
                                        </div>



                                        <div className='col-md-1'>
                                            <div className='d-grid' style={{marginTop:20}}>
                                                <button className={'btn btn3'} onClick={()=>getAttributes(1)} style={{fontWeight:'700'}}>
                                                    Chercher
                                                </button>
                                            </div>
                                        </div>                                    


                                    {
                                        /*

                                        <div className='col-md-3'>
                                        <br/>                                            
                                            <p className='' style={{fontWeight:'bolder', fontSize:18}}>Total: {totalItemsCount} </p> 
                                        </div>

                                        <div className='col-md-2'>
                                            <div className='d-grid' style={{marginTop:20}}>
                                                <button className={'btn btn-sm theme-button2'} onClick={()=>handleModal()} style={{}}>
                                                    <i className='fa-solid fa-user-plus'/> &nbsp;
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>                                                                            
                                         */
                                    }
                                    </div>
                                </div>

                                    <div className='col-md-12'>
    
                                    </div> 

                                    {isLoading ? <Loader/> :
                                            <div className='table-responsive soft-landing'>
                                            <table
      className={'my-table position-relative table table-hover table-striped table-bordered'}
      style={{  borderLeft: '0px solid white', borderRight: '0px solid white' }}
    >
                                            <thead style={{ borderBottom: '2px solid black' }}>
                                            <tr>
                                                <th style={{width:'20%'}}>Nom du rôle</th>
                                                <th style={{width:'40%'}}>Projets </th>
                                                <th style={{width:'20%'}}>Date</th>
                                                <th style={{width:'20%'}}>Action</th>
                                            </tr>
                        </thead>
                                                
                        <tbody>
                        {attributes && attributes.length > 0 ? attributes.map((attribute, index) =>(
                            <>
                                <tr key={index}>    
                                    <td>
                                        <b>{attribute.name}</b>
                                    </td>
                                    <td>

                                        {attribute.folders && attribute.folders.length > 0
                                            ? attribute.folders.map((folder, folderIndex) => (
                                                <span  className='' style={{ marginRight: 5, fontSize: 14, fontWeight:'bolder' }} key={folderIndex}><i className='fa fa-folder' style={{color:'#ffc108', fontSize:22}}></i> {folder.name}</span> 
                                            ))
                                            : 'Aucun projet n\'a été associé à ce rôle !'}
                                    </td>
                                    <td>
                                        <p className={'text-theme'}><small>Created: {attribute.created_at}</small></p>
                                        <p className={'text-theme'}><small>Updated: {attribute.updated_at}</small></p>                                            
                                    </td>

                                    <td>
                                    {userPermissions.some(permission => permission.name === 'edit_visibility') && (


                                            <button onClick={() => handleModal(attribute)} className={'btn btn-sm btn-warning my-1 mx-1'} style={{background:'none',border:'none', color:'#428bca', fontWeight:'500'}}>
                                                            <i className='fas fa-pen'></i>
                                                            &nbsp;
                                                            <text>modifier</text>
                                                        </button>


                                                    )}


                                        {
                                            /*
                                            <button onClick={()=>{handleRoleDelete(attribute.id)}} className='btn btn-sm
                                            btn-danger my-1'><i className='fa-solid fa-trash' /></button>                                                                                        
                                             */
                                        }
                                    </td>

                                </tr>
                                
                            </>
                                )):
                                <NoDataFound />
                        }
                        </tbody>
                                                
                                            </table>
                                            </div>
                                    }
    
                                </div>
                            </div>
                            <div className='card-footer'>
                            <nav className={'pagination-sm'}>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={getAttributes}
                                        nextPageText={'Next'}
                                        firstPageText={'first'}
                                        prevPageText={'Previous'}
                                        lastPageText={'last'}
                                        itemClass={'page-item'}
                                        linkClass={'page-link'}
                                    />
                                </nav>
                                </div>                            
                        </div>
                    </div>
                </div>              
                </>
        ) : (
        // Afficher l'alerte seulement si l'utilisateur n'a pas la permission
<>
{!userPermissions.some(permission => permission.name === 'view_visibility') && (
<>
<div className='alert alert-danger' role='alert' style={{display:''}}>
    Vous n'avez pas le droit d'accéder à ce contenu.
  </div>
</>
)}

</>
      )}    

          </>
          }        
                          
                <Modal centered show={modalShow} onHide={()=>setModalShow(false)} >
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-center">
{modalTitle} Visibilité
</Modal.Title>
</Modal.Header>
<Modal.Body>

        <label className={'w-100'}>
            <p>Nom du Rôle:  </p>
            <input className={errors && errors.name !==undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'
                } type={'text'} name={'name'} value={input.name} onChange={handleInput} 
                placeholder='Entrer Le Nom du Rôle' readOnly/>

            <p className={'login-error-msg'}>
                <small>
                    {errors && errors.name != undefined ? errors.name[0] : null}
                </small>
            </p>

        </label>
        <div className="folders-checkbox" style={{lineHeight:'33px'}}>
            {/* Affichez la liste des folders avec des cases à cocher */}
            {console.log(folders)}
            {folders.map((folder) => (
            <div key={folder.id}>
                <label>
                <i className='fa fa-folder' style={{color:'#ffc108', fontSize:22}}></i> &nbsp;
                <input
                                type="checkbox"
                                value={folder.id}
                                checked={selectedFolders.some(selectedFolder => selectedFolder.id === folder.id)}
                                onChange={() => handleFolderCheckbox(folder.id)}
                            />{' '}
                    
                    {folder.name} 
                        
                </label>
            </div>
            ))}
        </div>
        <button
    className={'btn btn3 mt-4 float-end'}
    style={{fontSize: 17, fontWeight: 'bolder'}}
    onClick={() => {
        //console.log("role: "+input.id);
        //console.log("folders ESSOH: "+ValueToPass);
        //const folderIdsArray = ValueToPass.split(',').map(Number);
    //handleFolderUpdate(input.id, selectedFolderIds);
    handleFolderUpdate(input.id, ValueToPass);
    }}
    dangerouslySetInnerHTML={{
        __html: isLoading
            ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...'
            : `${modalTitle} Visibilité`
    }}
/>


    </Modal.Body>

</Modal>



<Modal centered show={valueModalShow} onHide={()=>setValueModalShow(false)} >
<Modal.Header closeButton>
<Modal.Title id="contained-modal-title-vcenter">
{modalTitle} Attribute Value
</Modal.Title>
</Modal.Header>

    <Modal.Body>


        <label className={'w-100'}>
            <p>Name</p>
            <input className={errors && errors.name !==undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'
                } type={'text'} name={'name'} value={input.name} onChange={handleInput} placeholder='Enter Attribute
                Name' />

            <p className={'login-error-msg'}>
                <small>
                    {errors && errors.name != undefined ? errors.name[0] : null}
                </small>
            </p>

        </label>

        <button className={'btn theme-button mt-4'} onClick={isEditMode ? handleValueEdit : handleValueCreate}
            dangerouslySetInnerHTML={{ __html: isLoading
            ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading ...' :
            `${modalTitle} Value` }} />

    </Modal.Body>

</Modal>
    
     
            </>
        );
    };
    
    
    

export default Visibility;