import React, { useEffect, useState } from "react";
import BreadCrumb from "../partials/BreadCrumb";
import CardHeader from "../partials/miniComponent/CardHeader/CardHeader";
import axios from "axios";
import Constants from "../../Constants";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../partials/miniComponent/Loader";
import NoDataFound from "../partials/miniComponent/NoDataFound";
import folderfill from "../../assets/img/folder-fill.png";
import folderempty from "../../assets/img/folder-empty.png";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getUserPermissions } from "../../services/authService";

//import $ from 'jquery'; // Importez jQuery pour utiliser DataTables

const Dashboard = () => {
  const [input2, setInput2] = useState({
    order_by: "created_at",
    per_page: 10,
    direction: "desc",
    search: "",
    startDate: "", // Nouvelle entrée pour la date de début
    endDate: "", // Nouvelle entrée pour la date de fin
  });

  const [dataTable, setDataTable] = useState(null); // État pour stocker l'instance DataTable
  const [isLoading, setIsLoading] = useState(false);
  const [itemsCountPerPageData, setItemsCounterPerPageData] = useState(0);
  const [totalItemsCountData, setTotalItemsCountData] = useState(1);
  const [startFromData, setStartFromData] = useState("");
  const [activePageData, setActivePageData] = useState(1);
  const [datas, setDatas] = useState([]);

  const [userPermissions, setUserPermissions] = useState([]);
  const [foldersData, setFoldersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [userRole, setUserRole] = useState("");
  const itemsPerPage = 10;

  const fetchUserPermissions = async () => {
    try {
      const permissions = await getUserPermissions();
      setUserPermissions(permissions);
      console.log(userPermissions);
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  useEffect(() => {
    fetchUserPermissions();
    console.log("userPermissions");
    console.log(userPermissions);
  }, []);

  const WithLabelExample = () => {
    const now = 80;
    return <ProgressBar now={now} label={`${now}%`} />;
  };

  useEffect(() => {
    axios
      .get(`${Constants.BASE_URL}/resumeallfolders`)
      .then((response) => {
        setFoldersData(response.data.foldersData);
        //console.log(foldersData);
      })
      .catch((error) => {
        console.error("Error fetching folders data:", error);
      });
  }, []);

  useEffect(() => {
    // Filter the data based on the search term
    const filtered = foldersData.filter((folder) =>
      folder.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [foldersData, searchTerm]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setActivePage(1); // Reset to first page when search term changes
  };

  const startIndex = (activePage - 1) * itemsPerPage;
  const displayedData = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleInput = (e) => {
    setInput2((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  /*
    const getDatas = (pageNumber) =>{
        setIsLoadingData(true);
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}`).then(res => {        
            setDatas(res.data.data);
            setItemsCounterPerPageData(res.data.meta.per_page);
            setStartFromData(res.data.meta.from);
            setTotalItemsCountData(res.data.meta.total);
            setActivePageData(res.data.meta.current_page);
            setIsLoadingData(false);
            console.log(res.data);
        })
    }*/

  /*
    const getDatas = (pageNumber) => {
        setIsLoading(true);      
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}&profile=${selectedProfile}`)
          .then(res => {
            setDatas(res.data.data);
            setItemsCounterPerPageData(res.data.meta.per_page);
            setStartFromData(res.data.meta.from);
            setTotalItemsCountData(res.data.meta.total);
            setActivePageData(res.data.meta.current_page);
            setIsLoading(false);
            console.log(res.data);
          })
          .catch(error => {
            setIsLoading(false);
            console.error(error);
          });
      };
*/

  const [userCount, setUserCount] = useState(0);
  const [permissionCount, setPermissionCount] = useState(0);
  const [roleCount, setRoleCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [projectCountF, setProjectCountF] = useState(0);
  const [completedProjectCount, setCompletedProjectCount] = useState(0);
  const [folders, setFolders] = useState([]);
  const userEmail = localStorage.getItem("token");

  const refreshFolders = () => {
    setIsLoading(true);

    axios
      .get(`${Constants.BASE_URL}/list-root-folders-for-user-role`)
      .then((response) => {
        setIsLoading(false);
        const rootFolders = response.data.folders.filter(
          (folder) => !folder.parent_id
        );
        setFolders(rootFolders);
        console.log("rootFolders");
        console.log(rootFolders);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des dossiers", error);
      });
  };

  useEffect(() => {
    const userEmail = localStorage.getItem("email");
    if (userEmail) {
      axios
        .get(`${Constants.BASE_URL}/user-role`, {
          params: {
            email: userEmail, // ou token: userEmail selon votre API
          },
        })
        .then((response) => {
          const roleId = response.data.role_id;
          console.log("Role :", response.data.role_name);
          setUserRole(response.data.role_name);
          console.log("Role ID:", roleId);
          // Faites ce que vous devez avec le roleId, comme le stocker dans un état
        })
        .catch((error) => {
          console.error("Error fetching role ID:", error);
        });
    }
  }, []); // Le tableau vide [] signifie que cela s'exécutera une seule fois après le montage initial.

  useEffect(() => {
    console.log("userEmail");
    console.log(userEmail);
    refreshFolders();
    // Fonction pour récupérer les statistiques
    const fetchStats = async () => {
      try {
        const [
          userResponse,
          permissionResponse,
          roleResponse,
          projectResponse,
          projectCountF,
        ] = await Promise.all([
          axios
            .get(`${Constants.BASE_URL}/userscount`)
            .then((res) => setUserCount(res.data.userCount)),

          axios
            .get(`${Constants.BASE_URL}/permissionscount`)
            .then((res) => setPermissionCount(res.data.permissionCount)),

          axios
            .get(`${Constants.BASE_URL}/rolecount`)
            .then((res) => setRoleCount(res.data.roleCount)),

          axios
            .get(`${Constants.BASE_URL}/projetscount`)
            .then((res) => setProjectCount(res.data.projectCount)),

          axios
            .get(`${Constants.BASE_URL}/projetscountf`)
            .then((res) => setProjectCountF(res.data.projectCountF)),
        ]);

        const completedProjectResponse = await axios.get(
          "/api/projects/completed/count"
        );

        //setPermissionCount(permissionResponse.data.count);
        //setRoleCount(roleResponse.data.count);
        //setProjectCount(projectResponse.data.count);
        setCompletedProjectCount(completedProjectResponse.data.count);
      } catch (error) {
        console.error("Error fetching dashboard stats:", error);
      }
    };

    fetchStats();
  }, []); // Le tableau de dépendances vide signifie que cela s'exécutera une seule fois après le montage initial.

  const pieChartOptions = {
    chart: {
      type: "pie", // Utiliser un graphique de type camembert (pie chart)
    },
    title: {
      text: "Vue d'ensemble - circulaire",
    },
    series: [
      {
        name: "Projets",
        colorByPoint: true,
        data: [
          {
            name: "Projets Terminées",
            y: projectCountF,
            color: "#00e272", // Couleur pour les tâches terminées
          },
          {
            name: "Projets Non Terminées",
            y: projectCount - projectCountF,
            color: "#e50001", // Couleur pour les tâches non terminées
          },
        ],
      },
    ],
  };

  const chartOptions = {
    chart: {
      type: "column", // Utiliser un graphique de type colonne
    },
    title: {
      text: "Vue d'ensemble - en Batons",
    },
    xAxis: {
      categories: ["Total Projets"], // Catégorie unique pour le total des tâches
    },
    yAxis: {
      title: {
        text: "Nombre de Projets",
      },
    },
    series: [
      {
        name: "Projets Terminées",
        data: [projectCountF], // Données pour les tâches terminées
        color: "#00e272", // Couleur pour les tâches terminées
      },
      {
        name: "Projets Non Terminées",
        data: [projectCount - projectCountF], // Données pour les tâches non terminées
        color: "#2caffe", // Couleur pour les tâches non terminées
      },
    ],
  };

  return (
    <>
      <div className="row" style={{ marginTop: 40 }}>
        <h3 style={{ fontWeight: "bolder" }} className="mb-2">
          <i className="fa fa-dashboard" style={{ color: "#0f2a47" }}></i>{" "}
          Tableau de bord
        </h3>

        <div
          class="card col-md-3 mt-2 mb-4"
          style={{ border: 0, backgroundColor: "transparent" }}
        >
          <div
            style={{
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem",
              background: "white",
              color: "white",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{ fontWeight: "bolder", color: "black" }}
              >
                Utilisateurs
              </h5>
              <h5
                class="card-title float-end"
                style={{ color: "black", fontSize: 35, fontWeight: "600" }}
              >
                <i
                  className="fa fa-users"
                  style={{ fontSize: 35, color: "#607D8B" }}
                ></i>
                &nbsp;
                {userCount}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="card col-md-3 mt-2 mb-4"
          style={{ border: 0, backgroundColor: "transparent" }}
        >
          <div
            style={{
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem",
              background: "white",
              color: "white",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{ fontWeight: "bolder", color: "black" }}
              >
                Permissions
              </h5>
              <h5
                class="card-title float-end"
                style={{ color: "black", fontSize: 35, fontWeight: "600" }}
              >
                <i
                  className="fa fa-lock"
                  style={{ fontSize: 35, color: "#607D8B" }}
                ></i>
                &nbsp;
                {permissionCount}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="card col-md-3 mt-2 mb-4"
          style={{ border: 0, backgroundColor: "transparent" }}
        >
          <div
            style={{
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem",
              background: "white",
              color: "white",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{ fontWeight: "bolder", color: "black" }}
              >
                Rôles
              </h5>
              <h5
                class="card-title float-end"
                style={{ color: "black", fontSize: 35, fontWeight: "600" }}
              >
                <i
                  className="fa fa-user-group"
                  style={{ fontSize: 35, color: "#607D8B" }}
                ></i>
                &nbsp;
                {roleCount}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="card col-md-3 mt-2 mb-4"
          style={{ border: 0, backgroundColor: "transparent" }}
        >
          <div
            style={{
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem",
              background: "white",
              color: "white",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{ fontWeight: "bolder", color: "black" }}
              >
                Total projets
              </h5>
              <h5
                class="card-title float-end"
                style={{ color: "black", fontSize: 35, fontWeight: "600" }}
              >
                <i
                  className="fa fa-folder"
                  style={{ fontSize: 30, color: "#607D8B" }}
                ></i>
                &nbsp;
                {projectCount}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="card col-md-3 mt-2 mb-4"
          style={{ border: 0, backgroundColor: "transparent" }}
        >
          <div
            style={{
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem",
              background: "white",
              color: "white",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{ fontWeight: "bolder", color: "black" }}
              >
                Projets terminés
              </h5>
              <h5
                class="card-title float-end"
                style={{ color: "black", fontSize: 35, fontWeight: "600" }}
              >
                <i
                  className="fa fa-user-group"
                  style={{ fontSize: 30, color: "#607D8B" }}
                ></i>
                &nbsp;
                {projectCountF}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="card col-md-3 mt-2 mb-4"
          style={{ border: 0, backgroundColor: "transparent" }}
        >
          <div
            style={{
              border: "1px solid rgb(206, 206, 206)",
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem",
              background: "white",
              color: "white",
            }}
          >
            <div class="card-body">
              <h5
                class="card-title"
                style={{ fontWeight: "bolder", color: "black" }}
              >
                Projets en cours
              </h5>
              <h5
                class="card-title float-end"
                style={{ color: "black", fontSize: 35, fontWeight: "600" }}
              >
                <i
                  className="fa fa-folder"
                  style={{ fontSize: 30, color: "#607D8B" }}
                ></i>
                &nbsp;
                {projectCount - projectCountF}
              </h5>
            </div>
          </div>
        </div>

        {/*
    <div class="card col-md-4 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid rgb(206, 206, 206)', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem'}}>
    <div class="card-body">
      <h5 class="card-title">Total projets traités</h5>
      <h5 class="card-title float-end">20</h5>
    </div>
  </div>
  </div>

  <div class="card col-md-4 mt-2 mb-4" style={{border:0}}>
  <div style={{border:'1px solid rgb(206, 206, 206)', borderRadius:10, boxShadow:'rgba(0, 0, 0, 0.4) 0.5px 0px 0.2rem 0.1rem'}}>
    <div class="card-body">
      <h5 class="card-title">Total projets restants</h5>
      <h5 class="card-title float-end">20</h5>
    </div>
  </div>
  </div>
   */}
      </div>

      {/*
      <div className="row container mt-4 mb-5">
        <div className="col-md-6">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
        <div className="col-md-6">
          <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
        </div>
      </div>
       */}

       {userRole === "Admin" || userRole === "Super Admin" ? (
        <>
        <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg-primary text-white">
                <span
                  className="text-primary"
                  style={{ fontWeight: "bolder", fontSize: 18 }}
                >
                  <i className="fa fa-chart-bar"></i> Taille projets
                </span>
              </div>
              <div class="card-body">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header bg-primary text-white">
                <span
                  className="text-primary"
                  style={{ fontWeight: "bolder", fontSize: 18 }}
                >
                  <i className="fa fa-chart-pie"></i> Proportion projets
                </span>
              </div>
              <div class="card-body">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={pieChartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


        </>
       ):(
        <></>
       )}
      
      
       {userRole === "Admin" || userRole === "Super Admin" ? (
        <>
        <div class="container mt-3">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header text-white" style={{ background: "" }}>
                <h3 className="mb-0">
                  <i
                    className="fa fa-folder"
                    style={{ color: "#fff", fontSize: 16 }}
                  ></i>{" "}
                  <span
                    className="text-primary"
                    style={{ fontWeight: "bolder", fontSize: 18 }}
                  >
                    Tous les Projets
                  </span>
                  <div className="col-md-2 float-end">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="mot clé ..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      style={{ fontSize: 15 }}
                    />
                  </div>
                </h3>
              </div>
              <div class="card-body">
                <div className="container mt-4 mb-3">
                  <table
                    className="table table-hover"
                    style={{ borderRadius: 20 }}
                  >
                    <thead>
                      <tr>
                        <th>Projet (dossier client)</th>
                        <th>Evolution</th>
                        <th>Tâches créés</th>
                        <th>Tâches terminées</th>
                        <th>Tâches en cours</th>
                        <th>Observation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedData.map((folder) => (
                        <tr key={folder.folder_id}>
                          <td>
                            <Link
                              to={`/documents/details/${folder.folder_id}/${folder.folder_name}/`}
                              style={{ color: "rgb(8 30 128)" }}
                            >
                              <i
                                className="fas fa-folder"
                                style={{ color: "#ffc107", fontSize: 40 }}
                              ></i>{" "}
                              <br/>
                              <text style={{ fontSize: 14, fontWeight: "700" }}>
                                {folder.folder_name}
                              </text>
                            </Link>
                          </td>

                          {folder.total_tasks > 0 ? (
                            <td>
                              <ProgressBar
                                now={
                                  (folder.termine_count / folder.total_tasks) *
                                  100
                                }
                                label={`${
                                  (folder.termine_count / folder.total_tasks) *
                                  100
                                }%`}
                                className="mt-2 mb-2"
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}

                          <td>
                            <span className="badge bg-info2 badge-info">
                              {folder.total_tasks}
                            </span>
                          </td>

                          {folder.termine_count > 0 ? (
                            <td>
                              <span className="badge bg-info3 badge-info">
                                {folder.termine_count} / {folder.total_tasks}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <text
                                style={{
                                  fontSize: 14,
                                  background: "white",
                                  border: "1px solid #cecece",
                                  padding: 4,
                                  borderRadius: 4,
                                  boxShadow: "0 1px 0px rgba(0,0,0,.2)",
                                }}
                              >
                                Aucune
                              </text>
                            </td>
                          )}

                          {folder.en_cours_count > 0 ? (
                            <td>
                              <span className="badge bg-info4 badge-info">
                                {folder.en_cours_count}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <text
                                style={{
                                  fontSize: 14,
                                  background: "white",
                                  border: "1px solid #cecece",
                                  padding: 4,
                                  borderRadius: 4,
                                  boxShadow: "0 1px 0px rgba(0,0,0,.2)",
                                }}
                              >
                                Aucune
                              </text>
                            </td>
                          )}

                          {folder.termine_count > 0 &&
                          folder.total_tasks > 0 &&
                          folder.termine_count == folder.total_tasks ? (
                            <td>
                              <text
                                style={{
                                  fontSize: 13,
                                  background: "",
                                  color: "",
                                  border: "0px solid #cecece",
                                  padding: 4,
                                  borderRadius: 5,
                                  fontWeight: "bolder",
                                }}
                              >
                              
                                terminé
                                <br/>
                                <i
                                  className="fas fa-check-circle"
                                  style={{ color: "#00ad02", fontSize: 30 }}
                                ></i>{" "}
                              </text>
                            </td>
                          ) : (
                            <td>
                             
            <text
                                style={{
                                  fontSize: 13,
                                  background: "",
                                  color: "",
                                  border: "0px solid #cecece",
                                  padding: 4,
                                  borderRadius: 5,                                  
                                }}
                              >
                              
            non terminé
                                <br/>
                                <i
                                  className="fa-solid fa-triangle-exclamation"
                                  style={{ color: "#e50001", fontSize: 30 }}
                                ></i>{" "}
            </text>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={filteredData.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        </>
      ):(
        <></>
      )
      }


      {userRole !== "Admin" && userRole !== "Super Admin" ? (
        <>
        <div class="container mt-3">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header text-white" style={{ background: "" }}>
                <h3 className="mb-0">
                  <i
                    className="fa fa-folder"
                    style={{ color: "#fff", fontSize: 16 }}
                  ></i>{" "}
                  <span
                    className="text-primary"
                    style={{ fontWeight: "bolder", fontSize: 18 }}
                  >
                    Recapitulatif - projets autorisés
                  </span>
                  <div className="col-md-2 float-end">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="mot clé ..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      style={{ fontSize: 15 }}
                    />
                  </div>
                </h3>
              </div>
              <div class="card-body">
                <div className="container mt-4 mb-3">
                  <table
                    className="table table-hover"
                    style={{ borderRadius: 20 }}
                  >
                    <thead>
                      <tr>
                        <th>Projet (dossier client)</th>
                        <th>Evolution</th>
                        <th>Tâches créés</th>
                        <th>Tâches terminées</th>
                        <th>Tâches en cours</th>
                        <th>Observation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedData.map((folder) => {
                        const folderExistsInFolders = folders.some(
                          (f) => f.name === folder.folder_name
                        );
                        if (folderExistsInFolders) {
                          return (
                            <tr key={folder.folder_id}>
                              <td>
                                <Link
                                  to={`/documents/details/${folder.folder_id}/${folder.folder_name}/`}
                                  style={{ color: "rgb(8 30 128)" }}
                                >
                                  <i
                                    className="fas fa-folder"
                                    style={{ color: "#ffc107", fontSize: 40 }}
                                  ></i>{" "}
                                  <br/>
                                  <text
                                    style={{ fontSize: 14, fontWeight: "700" }}
                                  >
                                    {folder.folder_name}
                                  </text>
                                </Link>
                              </td>

                              {folder.total_tasks > 0 ? (
                                <td>
                                  <ProgressBar
                                    now={
                                      (folder.termine_count /
                                        folder.total_tasks) *
                                      100
                                    }
                                    label={`${
                                      (folder.termine_count /
                                        folder.total_tasks) *
                                      100
                                    }%`}
                                    className="mt-2 mb-2"
                                  />
                                </td>
                              ) : (
                                <td></td>
                              )}

                              <td>
                                <span className="badge bg-info2 badge-info">
                                  {folder.total_tasks}
                                </span>
                              </td>

                              {folder.termine_count > 0 ? (
                                <td>
                                  <span className="badge bg-info3 badge-info">
                                    {folder.termine_count} /{" "}
                                    {folder.total_tasks}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <text
                                    style={{
                                      fontSize: 14,
                                      background: "white",
                                      border: "1px solid #cecece",
                                      padding: 4,
                                      borderRadius: 4,
                                      boxShadow: "0 1px 0px rgba(0,0,0,.2)",
                                    }}
                                  >
                                    Aucune
                                  </text>
                                </td>
                              )}

                              {folder.en_cours_count > 0 ? (
                                <td>
                                  <span className="badge bg-info4 badge-info">
                                    {folder.en_cours_count}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <text
                                    style={{
                                      fontSize: 14,
                                      background: "white",
                                      border: "1px solid #cecece",
                                      padding: 4,
                                      borderRadius: 4,
                                      boxShadow: "0 1px 0px rgba(0,0,0,.2)",
                                    }}
                                  >
                                    Aucune
                                  </text>
                                </td>
                              )}

                              {folder.termine_count > 0 &&
                          folder.total_tasks > 0 &&
                          folder.termine_count == folder.total_tasks ? (
                            <td>
                              <text
                                style={{
                                  fontSize: 13,
                                  background: "",
                                  color: "",
                                  border: "0px solid #cecece",
                                  padding: 4,
                                  borderRadius: 5,
                                  fontWeight: "bolder",
                                }}
                              >
                              
                                terminé
                                <br/>
                                <i
                                  className="fas fa-check-circle"
                                  style={{ color: "#00ad02", fontSize: 30 }}
                                ></i>{" "}
                              </text>
                            </td>
                          ) : (
                            <td>
                             
            <text
                                style={{
                                  fontSize: 13,
                                  background: "",
                                  color: "",
                                  border: "0px solid #cecece",
                                  padding: 4,
                                  borderRadius: 5,                                  
                                }}
                              >
                              
            non terminé
                                <br/>
                                <i
                                  className="fa-solid fa-triangle-exclamation"
                                  style={{ color: "#e50001", fontSize: 30 }}
                                ></i>{" "}
            </text>
                            </td>
                          )}
                            </tr>
                          );
                        }
                        return null; // Ne rien afficher si le dossier n'est pas dans folders
                      })}
                    </tbody>
                  </table>

                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={filteredData.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>          
        </>
      ):(
        <></>
      )
      }

      
    </>
  );
};

export default Dashboard;
