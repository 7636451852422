// src/components/FolderForm.js
import Constants from '../Constants';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; // Importez les composants Modal et Button de React Bootstrap
import Swal from 'sweetalert2';
import { getUserPermissions } from "../services/authService";

const FolderForm = ({ onFolderCreate, currentFolderId, onPathChange, onRefreshFoldersD }) => {
    const [folderName, setFolderName] = useState('');
    const [parentFolder, setParentFolder] = useState('');
    const [existingFolders, setExistingFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedFolderToCompare, setSelectedFolderToCompare] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { id, name } = useParams();
    const [userPermissions, setUserPermissions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filterName, setFilterName] = useState('');
    const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAddFilesModal, setShowAddFilesModal] = useState(false);
  
    const handleCreateModalClose = () => setShowCreateModal(false);
    const handleAddFilesModalClose = () => setShowAddFilesModal(false);
  

    const handleRefresh = () => {
        // Rafraîchir la page
        window.location.reload();
      };

      const fetchUserPermissions = async () => {
        try {
          const permissions = await getUserPermissions();
          setUserPermissions(permissions);
          //console.log(userPermissions)
        } catch (error) {
          console.error("Error fetching user permissions:", error);
        }
      };
    
      useEffect(() => {
        fetchUserPermissions();
      }, []);
// Filtrer les dossiers
const filteredFolders = existingFolders.filter((folder) =>
folder.name.toLowerCase().includes(filterName.toLowerCase())
);

// Paginer les dossiers
const indexOfLastItem = currentPage * pageSize;
const indexOfFirstItem = indexOfLastItem - pageSize;
const currentItems = filteredFolders.slice(indexOfFirstItem, indexOfLastItem);

// Contrôles de pagination
const paginate = (pageNumber) => setCurrentPage(pageNumber);

  

    const handleFileChange = (e) => {
        setSelectedFiles([...selectedFiles, ...e.target.files]);
    };

    
    useEffect(() => {
        const fetchFolderDetails = async () => {
            try {
                // Vérifier si les paramètres id et name sont définis
                if (id && name) {
                    // Utilisez à la fois l'ID et le nom pour récupérer les détails du dossier
                    const folderResponse = await axios.get(`${Constants.BASE_URL}/folder-details/${id}/${name}`);
                    //console.log("folderName: "+folderName)
                    //console.log("parentFolder "+parentFolder)
                    //console.log("currentFolderId "+currentFolderId)
                    //console.log("id "+id)
                    //console.log(existingFolders)
                    //console.log(selectedFolderToCompare)
                    setSelectedFolder(folderResponse.data.folder);
                    //console.log('voila courant',folderResponse.data.folder.path);
                    setSelectedFolderToCompare(folderResponse.data.folder.path);
                    //console.log(folderResponse.data.folder.path);
                    onPathChange(folderResponse.data.folder.path);
                    //const contentResponse = await axios.get(`${Constants.BASE_URL}/folder-content/${id}`);
                    //setFolderContent(contentResponse.data.content);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des détails du dossier', error);
            }
        };
    
        fetchFolderDetails();
    }, [id, name]);
    

    useEffect(() => {
        // Charger la liste des répertoires existants depuis l'API
        const fetchExistingFolders = async () => {
            try {
                const response = await axios.get(`${Constants.BASE_URL}/list-folders`);
                setExistingFolders(response.data.folders);
            } catch (error) {
                console.error('Erreur lors de la récupération des dossiers', error);
            }
        };

        fetchExistingFolders();
    }, []);

    
    const handleCreateFolder = async (e) => {
        e.preventDefault();
    
        try {
            let newPath;
            if (id) {
                // Si un ID est présent, ajoutez le nouveau dossier au chemin existant
                newPath = selectedFolderToCompare + '/' + folderName;
            } else {
                // Sinon, créez un nouveau dossier à la racine
                newPath = (selectedFolderToCompare || '') + '/' + folderName;
            }
    
            // Envoyer la requête pour créer le dossier
            const response = await axios.post(`${Constants.BASE_URL}/create-folder`, {
                folderName,
                parentId: id,
                path: newPath,
            });
                // Afficher l'alerte avec SweetAlert2 après la création réussie
                Swal.fire({
                    position: 'top-end',
                    icon: response.data.cls,
                    title: response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 1500
                });

            console.log('id ', id);
            console.log('parent ', parentFolder);
            console.log('current ', selectedFolderToCompare);
            console.log('path', newPath);
            console.log('Dossier créé avec succès');
    
            // Actualiser la liste des dossiers existants après la création
            const newFolder = response.data.folder; // Supposons que l'API retourne l'objet du dossier créé
            setExistingFolders([...existingFolders, newFolder]);


    
            // Appeler la fonction de rappel (si fournie) pour gérer la création côté parent
            if (onFolderCreate) {
                onFolderCreate();
                setFolderName('');
            }
            setShowCreateModal(false); // Fermer la modal après la création du dossier

            // Mettez à jour la liste des dossiers après la création
            //fetchExistingFolders(); // Appel à la fonction pour récupérer la liste mise à jour
            //userPermissions();
            fetchUserPermissions();


        } catch (error) {
            console.error('Erreur lors de la création du dossier', error);
        }
    };
    

    const handleAddFiles = async (e) => {
        e.preventDefault();
    
        try {
            const formData = new FormData();
            for (const file of selectedFiles) {
                formData.append('files[]', file);
            }
    
            const response = await axios.post(`${Constants.BASE_URL}/add-files`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    parentId: selectedFolder?.id ?? null, // Utilisez null si selectedFolder est null
                    path: selectedFolderToCompare //+ '/' + selectedFiles[0]?.name, // Utilisez le nom du fichier comme partie du chemin
                },
            });
    
            console.log(response.data.message);
    
            const newFile = response.data.file; // Assurez-vous que l'API retourne l'objet du fichier créé
            console.log(response.data);
    
            setSelectedFiles([]);
            setShowAddFilesModal(false); // Fermer la modal après l'ajout des fichiers
            //userPermissions();

            //window.location.reload();            
            //COCO
            onRefreshFoldersD();
            // Afficher l'alerte avec SweetAlert2 après la création réussie
            Swal.fire({
                position: 'top-end',
                icon: response.data.cls,
                title: response.data.message,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            });
        } catch (error) {
            console.error('Erreur lors de l\'ajout de fichiers', error);
        }
    };
    
const fetchExistingFolders = async () => {
        try {
            const response = await axios.get(`${Constants.BASE_URL}/list-folders`);
            setExistingFolders(response.data.folders);
        } catch (error) {
            console.error('Erreur lors de la récupération des dossiers', error);
        }
    };
    
    // Utilisez currentFolderId comme valeur par défaut de parentFolder
    useEffect(() => {
        setParentFolder(currentFolderId || ''); // Si currentFolderId est défini, l'utiliser comme valeur par défaut
    }, [currentFolderId]);
    
// Sélectionnez la racine '/' comme destination par défaut si id et selectedFolderToCompare sont null
const defaultDestination = (id || selectedFolderToCompare) ? '' : '/';




    
    return (
<>
        <div className="car mb-4 mt-4">
            <div className="card-hader" style={{border:'none'}}>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="text-theme">
                       <i className='fa fa-folder-open'></i> Gérer Projets
                    </h4> 

                    <div className="float-end">
                    {userPermissions.some(permission => permission.name === 'create_project_and_sub_folder') && (
                        <button
                            className="btn btn-3 d-md-inline"
                            onClick={() => setShowCreateModal(true)}
                            style={{ borderColor: '#0ea71b', marginRight: 10, marginBottom:5, background:'#0ea71b', color:'white', fontSize:16, fontWeight:'bolder' }}
                        >
                            {id ? 'Créer un sous dossier' : 'Créer un projet'}
                        </button>
                                    )}                                    


{
    id ?(
                                <>
                                {userPermissions.some(permission => permission.name === 'add_file') && (
                                    <button
                    className="btn btn-success add-files d-md-inline"
                    onClick={() => setShowAddFilesModal(true)}
                    style={{ marginRight: -5, marginBottom:5 }}
                    >
                    Ajouter des fichiers
                    </button>                                    )}                                                                    

                                </>
                            ):''
}

</div>

                </div>
            </div>
            <div className="cad-bod" style={{background:'none', border: 'none'}}>
                        {
/*
                            !id ?(
                                <>
                                    &nbsp;
                                    <button className="btn btn-success add-files" onClick={handleRefresh}>
                                        Actualiser
                                    </button>
                                </>
                            ):''
 */
                        }


{/* Modal de création de dossier */}
      <Modal show={showCreateModal} onHide={handleCreateModalClose} style={{ marginTop: 0, backdropFilter: "blur(8px)" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:19}}>Création de Dossier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Votre formulaire de création de dossier */}
          <form onSubmit={handleCreateFolder} className="row g-3">
                    <div className="col-md-12">
                        <label htmlFor="folderName" className="form-label">Nom du dossier :</label>
                        <input
                            type="text"
                            id="folderName"
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                            required
                            className='form-control'
                            placeholder='Nom du projet'
                            style={{fontSize:16}}
                        />
                    </div>
 
{/*
                    <div className="col-md-4" style={{marginTop:47}}>
                        <button type="submit" className='btn btn-primary form-control'>Créer le dossier</button>
                    </div>
                    style={{ fontWeight: "bolder", background:'#000f51 !important;' }}
 */}
                </form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondar" className='btn31' onClick={handleCreateModalClose} style={{fontWeight:'bolder', fontSize:16, background:'#000f51 !important;'}}>
            Fermer
          </Button>
          <Button variant="primar" className='btn3' type="submit" onClick={handleCreateFolder} style={{fontWeight:'bolder', fontSize: 16}}>
            Créer
          </Button>
        </Modal.Footer>
      </Modal>
        &nbsp;
      {/* Bouton pour ouvrir la modal d'ajout de fichiers */}
      {id && (
<>
{/*
    <button className="btn btn-success add-files" onClick={() => setShowAddFilesModal(true)}>
        Ajouter des fichiers
    </button>&nbsp;
 */}

</>
                    )}

{/* Modal d'ajout de fichiers */}
<Modal show={showAddFilesModal} onHide={handleAddFilesModalClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:19}}>Ajout de Fichiers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Votre formulaire d'ajout de fichiers */}
          <form className="row g-3">
            <div className="col-md-12">
                <label htmlFor="files" className="form-label" style={{fontSize:16}}>Sélectionner des fichiers :</label>
                <input
                    type="file"
                    id="files"
                    multiple
                    onChange={handleFileChange}
                    className='form-control'
                    style={{fontSize:16}}
                />
            </div>
{/*
            <div className="col-md-4" style={{marginTop:48}}>
                <button type="button" className='btn btn-success form-control' onClick={handleAddFiles}>
                    Ajouter
                </button>
            </div>
 */}
        </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondar" onClick={handleAddFilesModalClose} style={{background:'#000f51', color:'white',fontWeight:'bolder'}}>
            Fermer
          </Button>
          <Button variant="success" type="submit" onClick={handleAddFiles} style={{background:'rgb(14, 167, 27)', color:'white',fontWeight:'bolder'}}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
            </div>
            
        </div>

{/* Formulaire d'ajout de fichiers */}


</>
    );
};

export default FolderForm;
